import React from 'react'
import Popup from 'reactjs-popup'
import { connect } from 'react-redux'

import { addComplaint } from '../../../../actions/phone'

class ModalAddComplaint extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			text: "",
			email: ""
		};
	}

	close() {
		this.setState({
			text: "",
			email: ""
		});

		this.props.close();
	}

	addComplaint() {
		let { text, email } = this.state;
		let { number } = this.props.match.params;

		this.props.addComplaint({
			text: text,
			email: email,
			number: number
		});

		this.close();
	}

	render() {
		let { text, email } = this.state;

		return (
			<Popup 
				modal
				open={true}
				onClose={() => this.close()}
			>
				{close => (
					<div className="modal modal-add-tag">

						<div className="close" onClick={() => close()}>
							×
						</div>

						<div className="content">
							<input
								className="email-input"
								placeholder="Почта для ответа"
								value={email}
								onChange={(e) => this.setState({email: e.target.value})}
							/>
							<textarea
								className="text-textarea"
								placeholder="Расскажите, что вас не устроило"
								value={text}
								onChange={(e) => this.setState({text: e.target.value})}
							/>
							<button 
								className="btn btn-transparent"
								onClick={() => this.addComplaint()}
							>
								Пожаловаться
							</button>
						</div>

					</div>
				)}
			</Popup>
		)
	}

}

const mapStateToProps = (state) => ({
	phone: state.phone
})

const mapDispatchToProps = (dispatch) => ({
	addComplaint: (data) => dispatch(addComplaint(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddComplaint);