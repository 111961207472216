import {
	GET_PHONE_LIST_REQUEST,
	GET_PHONE_LIST_FAILURE,
	GET_PHONE_LIST_SUCCESS,
	GET_ADMIN_INFO_REQUEST,
	GET_ADMIN_INFO_FAILURE,
	GET_ADMIN_INFO_SUCCESS,
	DELETE_ADMIN_TAG_REQUEST,
	DELETE_ADMIN_TAG_FAILURE,
	DELETE_ADMIN_TAG_SUCCESS,
	SEND_IMPORT_DATA_REQUEST,
	SEND_IMPORT_DATA_FAILURE,
	SEND_IMPORT_DATA_SUCCESS,
	IMPORT_ADMIN_FILE_REQUEST,
	IMPORT_ADMIN_FILE_FAILURE,
	IMPORT_ADMIN_FILE_SUCCESS,
	CHANGE_USER_RULES_REQUEST,
	CHANGE_USER_RULES_FAILURE,
	CHANGE_USER_RULES_SUCCESS,
	DELETE_ADMIN_PHONE_REQUEST,
	DELETE_ADMIN_PHONE_FAILURE,
	DELETE_ADMIN_PHONE_SUCCESS,
	GET_ADMIN_USER_LIST_REQUEST,
	GET_ADMIN_USER_LIST_FAILURE,
	GET_ADMIN_USER_LIST_SUCCESS,
	GET_ADMIN_ADDITIONALS_TAGS_REQUEST,
	GET_ADMIN_ADDITIONALS_TAGS_FAILURE,
	GET_ADMIN_ADDITIONALS_TAGS_SUCCESS,

	GET_COMPLAINT_LIST_REQUEST,
	GET_COMPLAINT_LIST_FAILURE,
	GET_COMPLAINT_LIST_SUCCESS,
	GET_QUESTION_LIST_REQUEST,
	GET_QUESTION_LIST_FAILURE,
	GET_QUESTION_LIST_SUCCESS,
	DELETE_QUESTION_REQUEST,
	DELETE_QUESTION_FAILURE,
	DELETE_QUESTION_SUCCESS,
	DELETE_COMPLAINT_REQUEST,
	DELETE_COMPLAINT_FAILURE,
	DELETE_COMPLAINT_SUCCESS,
} from './types'
import { fetchToAPI } from './optionsApp'

import config from '../config'

export const getPhonesList = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/admin/getPhonesList',
		data: data,
		request: res => {
			return { 
				type: GET_PHONE_LIST_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: GET_PHONE_LIST_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: GET_PHONE_LIST_SUCCESS,
				payload: res
			}
		}
	}))
}

export const getAdminAdditionalTags = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/phone/getAdditionalTags',
		data: data,
		request: res => {
			return { 
				type: GET_ADMIN_ADDITIONALS_TAGS_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: GET_ADMIN_ADDITIONALS_TAGS_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: GET_ADMIN_ADDITIONALS_TAGS_SUCCESS,
				payload: res
			}
		},
		serverError: res => {
			
		}
	}))
}

export const getInfo = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/admin/getInfo',
		data: data,
		request: res => {
			return { 
				type: GET_ADMIN_INFO_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: GET_ADMIN_INFO_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: GET_ADMIN_INFO_SUCCESS,
				payload: res
			}
		}
	}))
}

export const deletePhone = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/admin/deletePhone',
		data: data,
		request: res => {
			return { 
				type: DELETE_ADMIN_PHONE_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: DELETE_ADMIN_PHONE_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: DELETE_ADMIN_PHONE_SUCCESS,
				payload: res
			}
		}
	}))
}

export const deleteTag = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/admin/deleteTag',
		data: data,
		request: res => {
			return { 
				type: DELETE_ADMIN_TAG_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: DELETE_ADMIN_TAG_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: DELETE_ADMIN_TAG_SUCCESS,
				payload: res
			}
		}
	}))
}

export const getComplaintList = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/admin/getComplaintList',
		data: data,
		request: res => {
			return { 
				type: GET_COMPLAINT_LIST_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: GET_COMPLAINT_LIST_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: GET_COMPLAINT_LIST_SUCCESS,
				payload: res
			}
		}
	}))
}

export const getQuestionList = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/admin/getQuestionList',
		data: data,
		request: res => {
			return { 
				type: GET_QUESTION_LIST_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: GET_QUESTION_LIST_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: GET_QUESTION_LIST_SUCCESS,
				payload: res
			}
		}
	}))
}

export const deleteComplaint = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/admin/deleteComplaint',
		data: data,
		request: res => {
			return { 
				type: DELETE_COMPLAINT_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: DELETE_COMPLAINT_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: DELETE_COMPLAINT_SUCCESS,
				payload: res
			}
		}
	}))
}

export const deleteQuestion = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/admin/deleteQuestion',
		data: data,
		request: res => {
			return { 
				type: DELETE_QUESTION_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: DELETE_QUESTION_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: DELETE_QUESTION_SUCCESS,
				payload: res
			}
		}
	}))
}

export const userList = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/admin/userList',
		data: data,
		request: res => {
			return { 
				type: GET_ADMIN_USER_LIST_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: GET_ADMIN_USER_LIST_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: GET_ADMIN_USER_LIST_SUCCESS,
				payload: res
			}
		}
	}))
}

export const changeUserRules = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/admin/changeUserRules',
		data: data,
		request: res => {
			return { 
				type: CHANGE_USER_RULES_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: CHANGE_USER_RULES_FAILURE,
				payload: res
			}
		},
		success: res => {
			dispatch(userList())
			return { 
				type: CHANGE_USER_RULES_SUCCESS,
				payload: res
			}
		}
	}))
}

export const importFileRequest = () => ({ type: IMPORT_ADMIN_FILE_REQUEST })
export const importFileFailure = () => ({ type: IMPORT_ADMIN_FILE_FAILURE })
export const importFileSuccess = (data) => ({
	type: IMPORT_ADMIN_FILE_SUCCESS,
	payload: data
})

export const importFile = (file) => dispatch => {
	dispatch(importFileRequest())

	let formData = new FormData();
	formData.append('token', localStorage.token);
	formData.append('file', file);


	fetch(`${config.serverUrl}/admin/importFile`, {
		method: 'POST',
		body: formData
	})
	.then(res => res.json())
	.then(data => {
		if(data.error) return false;
		dispatch(importFileSuccess(data))
	})
}

export const sendImportData = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/admin/sendImportData',
		data: data,
		request: res => {
			return { 
				type: SEND_IMPORT_DATA_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: SEND_IMPORT_DATA_FAILURE,
				payload: res
			}
		},
		success: res => {
			dispatch(getPhonesList())
			return { 
				type: SEND_IMPORT_DATA_SUCCESS,
				payload: res
			}
		},
		serverError: res => {
			
		}
	}))
}
