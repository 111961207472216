import React from 'react'
import { connect } from 'react-redux'

import ModalDeletePhone from '../modals/deletePhone'
import ModalDeleteComplaint from '../modals/deleteComplaint'

import { 
	getComplaintList
} from '../../../actions/admin'

import CabinetTable from '../cabinetTable'

class AdminComplaints extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			modalDeletePhoneNumber: null,
			modalDeleteComplaintId: null,
		};
	}

	render() {
		let {
			modalDeletePhoneNumber,
			modalDeleteComplaintId
		} = this.state;

		let {
			email,
			complaints
		} = this.props.admin;

		return (
			<div className="admin-page-complaints content">

				<div className="head">
					<div className="indicator">
						Показано: <b>{complaints.list.length}</b> из <b>{complaints.count || 0}</b>
					</div>
					<div className="email">
						{email}
					</div>
				</div>

				<CabinetTable 
					{...this.props}
					filter={false}
					isLoaded={complaints.isLoaded}
					countItems={complaints.count}
					itemsCountPerPage={500}
					tbody={this.tableBody(complaints.list)}
					requestToServer={params => this.props.getComplaintList(params)}
				/>

				{modalDeletePhoneNumber ? 
					<ModalDeletePhone 
						data={{
							number: modalDeletePhoneNumber
						}}
						close={() => this.setState({modalDeletePhoneNumber: null})} 
					/> 
				: false}

				{modalDeleteComplaintId ? 
					<ModalDeleteComplaint 
						complaintId={modalDeleteComplaintId}
						close={() => this.setState({modalDeleteComplaintId: null})} 
					/> 
				: false}
			</div>
		)
	}

	tableBody(list) {
		if(!list.length) return false;

		let readyList = list.map((item, key) => {
			return (
				<tr key={key}>
					<td>
						<div className="blue-block">
							<div className="info">
								<div className="main row">
									<h1 className="number">{item.number}</h1>
									<div className="views">
										{item.count_views}
										<img src="./img/icons/eye.png" alt=""/>
									</div>
								</div>
								<div className="body">
									<div className="column">
										<div className="title">
											Почта для ответа:
										</div>
										<div className="text">
											{item.email}
										</div>
									</div>
									<div className="column">
										<div className="title">
											Жалоба:
										</div>
										<div className="text">
											{item.text}
										</div>
									</div>
								</div>
								<div className="buttons">
									<button className="btn btn-transparent" onClick={() => this.setState({modalDeleteComplaintId: item.id})} >
										Удалить жалобу
									</button>
									<button className="btn btn-transparent" onClick={() => this.setState({modalDeletePhoneNumber: item.number})} >
										Удалить номер
									</button>
								</div>
							</div>
						</div>
					</td>
				</tr>
			)
		});

		return readyList;
	}

}

const mapStateToProps = (state) => ({
	admin: state.admin
})

const mapDispatchToProps = (dispatch) => ({
	getComplaintList: (data) => dispatch(getComplaintList(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminComplaints);