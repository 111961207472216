import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import { registerEmail } from '../../../actions/authRegister'

import DivLink from '../../elements/divLink'

import '../index.scss'

class AuthRegister extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: '',
			checked: false,
			confirmPassword: ''
		};

		this.pressEnter = this.pressEnter.bind(this);
	}

	pressEnter(keyPress) {
		if(keyPress.keyCode === 13) {
			let {
				email,
				password,
				confirmPassword
			} = this.state;
	
			let allowRegisterEmail = email  && password && confirmPassword;

			if(allowRegisterEmail) this.registerEmail();
		}
	}

	componentDidMount() {
		window.addEventListener('keydown', this.pressEnter, false)
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.pressEnter, false);
	}

	handleCheck(value) {
		const { checked } = this.state;

		let newValue = checked !== 'checked' ? 'checked' : false;

		this.setState({checked: newValue});
	}

	registerEmail() {
		const {
			email,
			password,
			confirmPassword
		} = this.state;

		const {
			registerEmail
		} = this.props;

		registerEmail({
			email: email,
			password: password,
			confirm_password: confirmPassword,
			partner_id: localStorage.partnerId,
			invitation_code: localStorage.invitationСode
		});
	}

	render() {
		if(localStorage.token) return <Redirect to='/' />;
		
		const {
			email,
			//checked,
			//password,
			//confirmPassword
		} = this.state;
		
		//let allowRegisterEmail = email && checked && password && confirmPassword;
		//let allowRegisterEmail = email  && password && confirmPassword;

		const { panel } = this.props;

		return (
			<div className={`auth auth-register ${panel ? 'panel' : 'window'}`}>

				<div className="form">
					<div className="title">Регистрация</div>
					<div className="row subtitle">
						<span>Уже есть аккаунт?</span>
						<DivLink to="/auth/login" className="create-account">Войти</DivLink>
					</div>
					<div className="inputs">
						<input type="email" placeholder="Email" onChange={e => this.setState({email: e.target.value})}/>
						<input type="password" placeholder="Пароль" onChange={e => this.setState({password: e.target.value})}/>
						<input type="password" placeholder="Повтор пароля" onChange={e => this.setState({confirmPassword: e.target.value})}/>
					</div>

					{/*<div className="select-wrapper">
						<input 
							type="checkbox" 
							onChange={() => this.handleCheck()} 
							checked={checked} 
							required="required"
						/>
						<a href="/docs/client-agreement.pdf" target="_blank" rel="noopener noreferrer">
							Я принимаю пользовательское соглашение
						</a>
					</div>*/}

					<div className="buttons buttons-window">
						<div className="row">
							<button 
								className="btn btn-blue enter"
								onClick={() => email ? this.registerEmail() : false}
								// disabled={allowRegisterEmail ? '' : 'disabled'}
							>Создать</button>
							<DivLink to="/auth/recover" className="recover">Забыли пароль?</DivLink>
						</div>
					</div>

				</div>

				{/*<Footer/>*/}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	authRegister: state.authRegister
})

const mapDispatchToProps = (dispatch) => ({
	registerEmail: (data) => dispatch(registerEmail(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthRegister)