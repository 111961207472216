import {
	LOGIN_EMAIL_REQUEST,
	LOGIN_EMAIL_FAILURE,
	LOGIN_EMAIL_SUCCESS,
} from './types'
import { 
	fetchToAPI,
	createAuthLocalStorage,
} from './optionsApp'

import { getInfo } from './admin'

export const loginEmail = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/auth/loginEmail',
		data: data,
		method: 'POST',
		request: res => {
			return { 
				type: LOGIN_EMAIL_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: LOGIN_EMAIL_FAILURE,
				payload: res
			}
		},
		success: res => {
			dispatch(createAuthLocalStorage(res))
			dispatch(getInfo({token: localStorage.token}))
			return { 
				type: LOGIN_EMAIL_SUCCESS,
				payload: res
			}
		}
	}))
}