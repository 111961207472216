import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import { loginEmail } from '../../../../actions/authLogin'

import '../index.scss'

class AdminLogin extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: '',
		};

		this.pressEnter = this.pressEnter.bind(this);
	}

	pressEnter(keyPress) {
		if(keyPress.keyCode === 13) {
			let {
				email,
				password
			} = this.state;
	
			let allowLoginEmail = email && password;

			if(allowLoginEmail) this.loginEmail();
		}
	}

	componentDidMount() {
		window.addEventListener('keydown', this.pressEnter, false)
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.pressEnter, false);
	}

	loginEmail() {
		const {
			email,
			password
		} = this.state;

		const {
			loginEmail
		} = this.props;

		loginEmail({
			email: email,
			password: password
		});
	}

	render() {
		if(localStorage.token && localStorage.isAdmin) return <Redirect to='/admin/phones' />;
		
		const {
			email,
			password,
		} = this.state;

		let allowLoginEmail = email && password;

		const { panel } = this.props;

		return (
			<div className={`admin login ${panel ? 'panel' : 'window'}`}>
				<div className="form">
					<div className="title">Админ-панель</div>
					<div className="inputs">
						<input type="email" placeholder="Email" onChange={e => this.setState({email: e.target.value})}/>
						<input type="password" placeholder="Пароль" onChange={e => this.setState({password: e.target.value})}/>
					</div>

					<div className="buttons buttons-window">
						<div className="row">
							<button 
								className="btn btn-transparent enter"
								onClick={() => allowLoginEmail ? this.loginEmail() : false}
								// disabled={allowLoginEmail ? '' : 'disabled'}
							>Войти</button>
						</div>
					</div>

				</div>

				{/*<Footer/>*/}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	authLogin: state.authLogin
})

const mapDispatchToProps = (dispatch) => ({
	loginEmail: (data) => dispatch(loginEmail(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin)