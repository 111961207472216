import {
	ADD_PHONE_TAG_REQUEST,
	ADD_PHONE_TAG_FAILURE,
	ADD_PHONE_TAG_SUCCESS,
	GET_PHONE_INFO_REQUEST,
	GET_PHONE_INFO_FAILURE,
	GET_PHONE_INFO_SUCCESS,
	ADD_PHONE_NUMBER_REQUEST,
	ADD_PHONE_NUMBER_FAILURE,
	ADD_PHONE_NUMBER_SUCCESS,
	ADD_PHONE_QUESTION_REQUEST,
	ADD_PHONE_QUESTION_FAILURE,
	ADD_PHONE_QUESTION_SUCCESS,
	ADD_PHONE_COMPLAINT_REQUEST,
	ADD_PHONE_COMPLAINT_FAILURE,
	ADD_PHONE_COMPLAINT_SUCCESS,
	GET_ADDITIONALS_TAGS_REQUEST,
	GET_ADDITIONALS_TAGS_FAILURE,
	GET_ADDITIONALS_TAGS_SUCCESS,
} from '../actions/types';

const initialState = {
	
}

export default function(state = initialState, action ) {
	switch(action.type) {
			
		case GET_PHONE_INFO_REQUEST:
			return {
				isLoaded: false,
			}
		case GET_PHONE_INFO_FAILURE:
			return {
				...state,
				...action.payload,
				isLoaded: false,
			}
		case GET_PHONE_INFO_SUCCESS:
			return {
				...state,
				...action.payload,
				isLoaded: true
			}

		case GET_ADDITIONALS_TAGS_REQUEST:
			return {
				...state,
			}
		case GET_ADDITIONALS_TAGS_FAILURE:
			return {
				...state,
				...action.payload,
			}
		case GET_ADDITIONALS_TAGS_SUCCESS:
			return {
				...state,
				tags: [...state.tags, ...action.payload.tags],
			}

		case ADD_PHONE_NUMBER_REQUEST:
			return {
				...state,
			}
		case ADD_PHONE_NUMBER_FAILURE:
			return {
				...state,
				...action.payload
			}
		case ADD_PHONE_NUMBER_SUCCESS:
			return {
				...action.payload,
				newNumber: true,
				isLoaded: true
			}

		case ADD_PHONE_TAG_REQUEST:
			return {
				...state,
			}
		case ADD_PHONE_TAG_FAILURE:
			return {
				...state,
				...action.payload
			}
		case ADD_PHONE_TAG_SUCCESS:
			return {
				...state,
				...action.payload
			}

		case ADD_PHONE_COMPLAINT_REQUEST:
			return {
				...state,
			}
		case ADD_PHONE_COMPLAINT_FAILURE:
			return {
				...state,
				...action.payload
			}
		case ADD_PHONE_COMPLAINT_SUCCESS:
			return {
				...state,
				...action.payload
			}

		case ADD_PHONE_QUESTION_REQUEST:
			return {
				...state,
			}
		case ADD_PHONE_QUESTION_FAILURE:
			return {
				...state,
				...action.payload
			}
		case ADD_PHONE_QUESTION_SUCCESS:
			return {
				...state,
				...action.payload
			}

		default: 
			return state;
	}
}