import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import { 
	recoverPassword
} from '../../../actions/authRecover'

import DivLink from '../../elements/divLink'

import '../index.scss'

class AuthRecover extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			email: ''
		};

		this.pressEnter = this.pressEnter.bind(this);
	}

	pressEnter(keyPress) {
		if(keyPress.keyCode === 13) {
			if(this.state.email) this.recoverPassword();
		}
	}

	componentDidMount() {
		window.addEventListener('keydown', this.pressEnter, false)
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.pressEnter, false);
	}

	recoverPassword() {
		const {
			email
		} = this.state;

		const {
			recoverPassword
		} = this.props;

		recoverPassword({
			email: email
		});
	}

	render() {
		if(localStorage.token) return <Redirect to='/' />;
		
		const {
			email
		} = this.state;

		const { panel } = this.props;

		return (
			<div className={`auth auth-recover ${panel ? 'panel' : 'window'}`}>
				{/*<Header/>*/}

				<div className="form">
					<div className="title">Отправить новый пароль</div>
					<div className="inputs">
						<input type="email" placeholder="E-mail" onChange={e => this.setState({email: e.target.value})}/>
					</div>

					<div className="buttons buttons-window">
						<div className="row">
							<button 
								className="btn btn-blue enter"
								onClick={() => email ? this.recoverPassword() : false}
								// disabled={email ? '' : 'disabled'}
							>Отправить</button>
							<DivLink to="/auth/login" className="btn btn-black back">Назад</DivLink>
						</div>
					</div>

				</div>

				{/*<Footer/>*/}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	authRecover: state.authRecover
})

const mapDispatchToProps = (dispatch) => ({
	recoverPassword: (data) => dispatch(recoverPassword(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthRecover)