export const SHOW_MODAL_CROP = 'SHOW_MODAL_CROP';
export const HIDE_MODAL_CROP = 'HIDE_MODAL_CROP';
export const ENABLE_ALERT_ERROR = 'ENABLE_ALERT_ERROR';
export const DISABLE_ALERT_ERROR = 'DISABLE_ALERT_ERROR';
export const ENABLE_ALERT_SUCCESS = 'ENABLE_ALERT_SUCCESS';
export const DISABLE_ALERT_SUCCESS = 'DISABLE_ALERT_SUCCESS';
export const BACK_TO_FORM_PHONE = 'BACK_TO_FORM_PHONE';

export const UPDATE_BANNER_IMAGE_REQUEST = 'UPDATE_BANNER_IMAGE_REQUEST';
export const UPDATE_BANNER_IMAGE_FAILURE = 'UPDATE_BANNER_IMAGE_FAILURE';
export const UPDATE_BANNER_IMAGE_SUCCESS = 'UPDATE_BANNER_IMAGE_SUCCESS';

export const GET_DASHBOARD_STATS_REQUEST = 'GET_DASHBOARD_STATS_REQUEST';
export const GET_DASHBOARD_STATS_FAILURE = 'GET_DASHBOARD_STATS_FAILURE';
export const GET_DASHBOARD_STATS_SUCCESS = 'GET_DASHBOARD_STATS_SUCCESS';

export const GET_SITES_HISTORY_REQUEST = 'GET_SITES_HISTORY_REQUEST';
export const GET_SITES_HISTORY_FAILURE = 'GET_SITES_HISTORY_FAILURE';
export const GET_SITES_HISTORY_SUCCESS = 'GET_SITES_HISTORY_SUCCESS';

export const GET_SITE_SETTINGS_REQUEST = 'GET_SITES_SETTINGS_REQUEST';
export const GET_SITE_SETTINGS_FAILURE = 'GET_SITES_SETTINGS_FAILURE';
export const GET_SITE_SETTINGS_SUCCESS = 'GET_SITES_SETTINGS_SUCCESS';

export const SAVE_SITE_SETTINGS_REQUEST = 'SAVE_SITES_SETTINGS_REQUEST';
export const SAVE_SITE_SETTINGS_FAILURE = 'SAVE_SITES_SETTINGS_FAILURE';
export const SAVE_SITE_SETTINGS_SUCCESS = 'SAVE_SITES_SETTINGS_SUCCESS';

export const DELETE_SITE_REQUEST = 'DELETE_SITE_REQUEST';
export const DELETE_SITE_FAILURE = 'DELETE_SITE_FAILURE';
export const DELETE_SITE_SUCCESS = 'DELETE_SITE_SUCCESS';

export const GET_ACCOUNT_INFO_REQUEST = 'GET_ACCOUNT_INFO_REQUEST';
export const GET_ACCOUNT_INFO_FAILURE = 'GET_ACCOUNT_INFO_FAILURE';
export const GET_ACCOUNT_INFO_SUCCESS = 'GET_ACCOUNT_INFO_SUCCESS';

export const GET_ACCOUNT_SESSIONS_REQUEST = 'GET_ACCOUNT_SESSIONS_REQUEST';
export const GET_ACCOUNT_SESSIONS_FAILURE = 'GET_ACCOUNT_SESSIONS_FAILURE';
export const GET_ACCOUNT_SESSIONS_SUCCESS = 'GET_ACCOUNT_SESSIONS_SUCCESS';

export const DISCONNECT_SESSION_REQUEST = 'DISCONNECT_SESSION_REQUEST';
export const DISCONNECT_SESSION_FAILURE = 'DISCONNECT_SESSION_FAILURE';
export const DISCONNECT_SESSION_SUCCESS = 'DISCONNECT_SESSION_SUCCESS';

export const SAVE_ACCOUNT_INFO_REQUEST = 'SAVE_ACCOUNT_INFO_REQUEST';
export const SAVE_ACCOUNT_INFO_FAILURE = 'SAVE_ACCOUNT_INFO_FAILURE';
export const SAVE_ACCOUNT_INFO_SUCCESS = 'SAVE_ACCOUNT_INFO_SUCCESS';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';

export const GET_BANNERS_HISTORY_REQUEST = 'GET_BANNERS_HISTORY_REQUEST';
export const GET_BANNERS_HISTORY_FAILURE = 'GET_BANNERS_HISTORY_FAILURE';
export const GET_BANNERS_HISTORY_SUCCESS = 'GET_BANNERS_HISTORY_SUCCESS';

export const GET_BANNER_SETTINGS_REQUEST = 'GET_BANNER_SETTINGS_REQUEST';
export const GET_BANNER_SETTINGS_FAILURE = 'GET_BANNER_SETTINGS_FAILURE';
export const GET_BANNER_SETTINGS_SUCCESS = 'GET_BANNER_SETTINGS_SUCCESS';

export const SAVE_BANNER_SETTINGS_REQUEST = 'SAVE_BANNER_SETTINGS_REQUEST';
export const SAVE_BANNER_SETTINGS_FAILURE = 'SAVE_BANNER_SETTINGS_FAILURE';
export const SAVE_BANNER_SETTINGS_SUCCESS = 'SAVE_BANNER_SETTINGS_SUCCESS';

export const DELETE_BANNER_REQUEST = 'DELETE_BANNER_REQUEST';
export const DELETE_BANNER_FAILURE = 'DELETE_BANNER_FAILURE';
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS';

export const LOGIN_EMAIL_REQUEST = 'LOGIN_EMAIL_REQUEST';
export const LOGIN_EMAIL_FAILURE = 'LOGIN_EMAIL_FAILURE';
export const LOGIN_EMAIL_SUCCESS = 'LOGIN_EMAIL_SUCCESS';

export const LOGIN_PHONE_REQUEST = 'LOGIN_PHONE_REQUEST';
export const LOGIN_PHONE_FAILURE = 'LOGIN_PHONE_FAILURE';
export const LOGIN_PHONE_SUCCESS = 'LOGIN_PHONE_SUCCESS';

export const CONFIRM_LOGIN_PHONE_REQUEST = 'CONFIRM_LOGIN_PHONE_REQUEST';
export const CONFIRM_LOGIN_PHONE_FAILURE = 'CONFIRM_LOGIN_PHONE_FAILURE';
export const CONFIRM_LOGIN_PHONE_SUCCESS = 'CONFIRM_LOGIN_PHONE_SUCCESS';

export const LOGOUT_EMAIL_REQUEST = 'LOGOUT_EMAIL_REQUEST';
export const LOGOUT_EMAIL_FAILURE = 'LOGOUT_EMAIL_FAILURE';
export const LOGOUT_EMAIL_SUCCESS = 'LOGOUT_EMAIL_SUCCESS';

export const RECOVER_PASSWORD_REQUEST = 'RECOVER_PASSWORD_REQUEST';
export const RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';

export const REGISTER_EMAIL_REQUEST = 'REGISTER_EMAIL_REQUEST';
export const REGISTER_EMAIL_FAILURE = 'REGISTER_EMAIL_FAILURE';
export const REGISTER_EMAIL_SUCCESS = 'REGISTER_EMAIL_SUCCESS';

export const GET_URL_INCREASE_REQUEST = 'GET_URL_INCREASE_REQUEST';
export const GET_URL_INCREASE_FAILURE = 'GET_URL_INCREASE_FAILURE';
export const GET_URL_INCREASE_SUCCESS = 'GET_URL_INCREASE_SUCCESS';

export const SEND_OUTPUT_REQUEST = 'SEND_OUTPUT_REQUEST';
export const SEND_OUTPUT_FAILURE = 'SEND_OUTPUT_FAILURE';
export const SEND_OUTPUT_SUCCESS = 'SEND_OUTPUT_SUCCESS';

export const GET_BALANCE_REQUEST = 'GET_BALANCE_REQUEST';
export const GET_BALANCE_FAILURE = 'GET_BALANCE_FAILURE';
export const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS';

export const CHANGE_PROFILE_INFO_REQUEST = 'CHANGE_PROFILE_INFO_REQUEST';
export const CHANGE_PROFILE_INFO_FAILURE = 'CHANGE_PROFILE_INFO_FAILURE';
export const CHANGE_PROFILE_INFO_SUCCESS = 'CHANGE_PROFILE_INFO_SUCCESS';

export const CHECK_PROMOCODE_REQUEST = 'CHECK_PROMOCODE_REQUEST';
export const CHECK_PROMOCODE_FAILURE = 'CHECK_PROMOCODE_FAILURE';
export const CHECK_PROMOCODE_SUCCESS = 'CHECK_PROMOCODE_SUCCESS';

export const GET_HISTORY_OUTPUT_REQUEST = 'GET_HISTORY_OUTPUT_REQUEST';
export const GET_HISTORY_OUTPUT_FAILURE = 'GET_HISTORY_OUTPUT_FAILURE';
export const GET_HISTORY_OUTPUT_SUCCESS = 'GET_HISTORY_OUTPUT_SUCCESS';

export const GET_HISTORY_INCREASES_REQUEST = 'GET_HISTORY_INCREASES_REQUEST';
export const GET_HISTORY_INCREASES_FAILURE = 'GET_HISTORY_INCREASES_FAILURE';
export const GET_HISTORY_INCREASES_SUCCESS = 'GET_HISTORY_INCREASES_SUCCESS';

export const GET_HISTORY_PROMOCODES_REQUEST = 'GET_HISTORY_PROMOCODES_REQUEST';
export const GET_HISTORY_PROMOCODES_FAILURE = 'GET_HISTORY_PROMOCODES_FAILURE';
export const GET_HISTORY_PROMOCODES_SUCCESS = 'GET_HISTORY_PROMOCODES_SUCCESS';


export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';

export const GET_APP_INFO_REQUEST = 'GET_APP_INFO_REQUEST';
export const GET_APP_INFO_FAILURE = 'GET_APP_INFO_FAILURE';
export const GET_APP_INFO_SUCCESS = 'GET_APP_INFO_SUCCESS';

export const UPDATE_APP_REQUEST = 'UPDATE_APP_REQUEST';
export const UPDATE_APP_FAILURE = 'UPDATE_APP_FAILURE';
export const UPDATE_APP_SUCCESS = 'UPDATE_APP_SUCCESS';

export const GET_INCREASE_INFO_REQUEST = 'GET_INCREASE_INFO_REQUEST';
export const GET_INCREASE_INFO_FAILURE = 'GET_INCREASE_INFO_FAILURE';
export const GET_INCREASE_INFO_SUCCESS = 'GET_INCREASE_INFO_SUCCESS';

export const GET_VIEW_BANNER_REQUEST = 'GET_VIEW_BANNER_REQUEST';
export const GET_VIEW_BANNER_FAILURE = 'GET_VIEW_BANNER_FAILURE';
export const GET_VIEW_BANNER_SUCCESS = 'GET_VIEW_BANNER_SUCCESS';

export const SAVE_VIEW_BANNER_REQUEST = 'SAVE_VIEW_BANNER_REQUEST';
export const SAVE_VIEW_BANNER_FAILURE = 'SAVE_VIEW_BANNER_FAILURE';
export const SAVE_VIEW_BANNER_SUCCESS = 'SAVE_VIEW_BANNER_SUCCESS';

export const CREATE_BANNER_REQUEST = 'CREATE_BANNER_REQUEST';
export const CREATE_BANNER_FAILURE = 'CREATE_BANNER_FAILURE';
export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS';

export const UPDATE_BANNER_STATUS_REQUEST = 'UPDATE_BANNER_STATUS_REQUEST';
export const UPDATE_BANNER_STATUS_FAILURE = 'UPDATE_BANNER_STATUS_FAILURE';
export const UPDATE_BANNER_STATUS_SUCCESS = 'UPDATE_BANNER_STATUS_SUCCESS';

export const GET_TARIFFS_REQUEST = 'GET_TARIFFS_REQUEST';
export const GET_TARIFFS_FAILURE = 'GET_TARIFFS_FAILURE';
export const GET_TARIFFS_SUCCESS = 'GET_TARIFFS_SUCCESS';

export const GET_REFERRALS_LIST_REQUEST = 'GET_REFERRALS_LIST_REQUEST';
export const GET_REFERRALS_LIST_FAILURE = 'GET_REFERRALS_LIST_FAILURE';
export const GET_REFERRALS_LIST_SUCCESS = 'GET_REFERRALS_LIST_SUCCESS';

export const GET_REFERRALS_INFO_REQUEST = 'GET_REFERRALS_INFO_REQUEST';
export const GET_REFERRALS_INFO_FAILURE = 'GET_REFERRALS_INFO_FAILURE';
export const GET_REFERRALS_INFO_SUCCESS = 'GET_REFERRALS_INFO_SUCCESS';

export const GET_ORDER_LIST_REQUEST = 'GET_ORDER_LIST_REQUEST';
export const GET_ORDER_LIST_FAILURE = 'GET_ORDER_LIST_FAILURE';
export const GET_ORDER_LIST_SUCCESS = 'GET_ORDER_LIST_SUCCESS';

export const GET_MY_ORDER_LIST_REQUEST = 'GET_MY_ORDER_LIST_REQUEST';
export const GET_MY_ORDER_LIST_FAILURE = 'GET_MY_ORDER_LIST_FAILURE';
export const GET_MY_ORDER_LIST_SUCCESS = 'GET_MY_ORDER_LIST_SUCCESS';

export const CREATE_LICENSE_KEY_TEST_REQUEST = 'CREATE_LICENSE_KEY_TEST_REQUEST';
export const CREATE_LICENSE_KEY_TEST_FAILURE = 'CREATE_LICENSE_KEY_TEST_FAILURE';
export const CREATE_LICENSE_KEY_TEST_SUCCESS = 'CREATE_LICENSE_KEY_TEST_SUCCESS';

export const SEND_OUTPUT_REQUEST_REQUEST = 'SEND_OUTPUT_REQUEST_REQUEST';
export const SEND_OUTPUT_REQUEST_FAILURE = 'SEND_OUTPUT_REQUEST_FAILURE';
export const SEND_OUTPUT_REQUEST_SUCCESS = 'SEND_OUTPUT_REQUEST_SUCCESS';

export const ADD_TO_FAVORITES_REQUEST = 'ADD_TO_FAVORITES_REQUEST';
export const ADD_TO_FAVORITES_FAILURE = 'ADD_TO_FAVORITES_FAILURE';
export const ADD_TO_FAVORITES_SUCCESS = 'ADD_TO_FAVORITES_SUCCESS';

export const ADD_TO_NOCALL_REQUEST = 'ADD_TO_NOCALL_REQUEST';
export const ADD_TO_NOCALL_FAILURE = 'ADD_TO_NOCALL_FAILURE';
export const ADD_TO_NOCALL_SUCCESS = 'ADD_TO_NOCALL_SUCCESS';

export const ADD_TO_BLACKLIST_REQUEST = 'ADD_TO_BLACKLIST_REQUEST';
export const ADD_TO_BLACKLIST_FAILURE = 'ADD_TO_BLACKLIST_FAILURE';
export const ADD_TO_BLACKLIST_SUCCESS = 'ADD_TO_BLACKLIST_SUCCESS';

export const GET_ADMIN_INFO_REQUEST = 'GET_ADMIN_INFO_REQUEST';
export const GET_ADMIN_INFO_FAILURE = 'GET_ADMIN_INFO_FAILURE';
export const GET_ADMIN_INFO_SUCCESS = 'GET_ADMIN_INFO_SUCCESS';

export const GET_ADMIN_USER_LIST_REQUEST = 'GET_ADMIN_USER_LIST_REQUEST';
export const GET_ADMIN_USER_LIST_FAILURE = 'GET_ADMIN_USER_LIST_FAILURE';
export const GET_ADMIN_USER_LIST_SUCCESS = 'GET_ADMIN_USER_LIST_SUCCESS';

export const CHANGE_USER_BALANCE_REQUEST = 'CHANGE_USER_BALANCE_REQUEST';
export const CHANGE_USER_BALANCE_FAILURE = 'CHANGE_USER_BALANCE_FAILURE';
export const CHANGE_USER_BALANCE_SUCCESS = 'CHANGE_USER_BALANCE_SUCCESS';

export const CHANGE_USER_RULES_REQUEST = 'CHANGE_USER_RULES_REQUEST';
export const CHANGE_USER_RULES_FAILURE = 'CHANGE_USER_RULES_FAILURE';
export const CHANGE_USER_RULES_SUCCESS = 'CHANGE_USER_RULES_SUCCESS';

export const CHANGE_LEAD_COST_REQUEST = 'CHANGE_LEAD_COST_REQUEST';
export const CHANGE_LEAD_COST_FAILURE = 'CHANGE_LEAD_COST_FAILURE';
export const CHANGE_LEAD_COST_SUCCESS = 'CHANGE_LEAD_COST_SUCCESS';

export const CHANGE_MINUS_KEYWORDS_REQUEST = 'CHANGE_MINUS_KEYWORDS_REQUEST';
export const CHANGE_MINUS_KEYWORDS_FAILURE = 'CHANGE_MINUS_KEYWORDS_FAILURE';
export const CHANGE_MINUS_KEYWORDS_SUCCESS = 'CHANGE_MINUS_KEYWORDS_SUCCESS';

export const ADD_TARIFF_REQUEST = 'ADD_TARIFF_REQUEST';
export const ADD_TARIFF_FAILURE = 'ADD_TARIFF_FAILURE';
export const ADD_TARIFF_SUCCESS = 'ADD_TARIFF_SUCCESS';

export const UPDATE_TARIFF_REQUEST = 'UPDATE_TARIFF_REQUEST';
export const UPDATE_TARIFF_FAILURE = 'UPDATE_TARIFF_FAILURE';
export const UPDATE_TARIFF_SUCCESS = 'UPDATE_TARIFF_SUCCESS';

export const DELETE_TARIFF_REQUEST = 'DELETE_TARIFF_REQUEST';
export const DELETE_TARIFF_FAILURE = 'DELETE_TARIFF_FAILURE';
export const DELETE_TARIFF_SUCCESS = 'DELETE_TARIFF_SUCCESS';

export const CHANGE_WALLET_ID_REQUEST = 'CHANGE_WALLET_ID_REQUEST';
export const CHANGE_WALLET_ID_FAILURE = 'CHANGE_WALLET_ID_FAILURE';
export const CHANGE_WALLET_ID_SUCCESS = 'CHANGE_WALLET_ID_SUCCESS';

export const CREATE_PAYMENT_TARIFF_TEST_REQUEST = 'CREATE_PAYMENT_TARIFF_TEST_REQUEST';
export const CREATE_PAYMENT_TARIFF_TEST_FAILURE = 'CREATE_PAYMENT_TARIFF_TEST_FAILURE';
export const CREATE_PAYMENT_TARIFF_TEST_SUCCESS = 'CREATE_PAYMENT_TARIFF_TEST_SUCCESS';

export const ALLOW_TARIFF_TEST_REQUEST = 'ALLOW_TARIFF_TEST_REQUEST';
export const ALLOW_TARIFF_TEST_FAILURE = 'ALLOW_TARIFF_TEST_FAILURE';
export const ALLOW_TARIFF_TEST_SUCCESS = 'ALLOW_TARIFF_TEST_SUCCESS';

export const STREAM_GET_INFO_REQUEST = 'STREAM_GET_INFO_REQUEST';
export const STREAM_GET_INFO_FAILURE = 'STREAM_GET_INFO_FAILURE';
export const STREAM_GET_INFO_SUCCESS = 'STREAM_GET_INFO_SUCCESS';

export const STREAM_CREATE_PAYMENT_REQUEST = 'STREAM_CREATE_PAYMENT_REQUEST';
export const STREAM_CREATE_PAYMENT_FAILURE = 'STREAM_CREATE_PAYMENT_FAILURE';
export const STREAM_CREATE_PAYMENT_SUCCESS = 'STREAM_CREATE_PAYMENT_SUCCESS';

export const GET_LIST_CATEGORIES_REQUEST = 'GET_LIST_CATEGORIES_REQUEST';
export const GET_LIST_CATEGORIES_FAILURE = 'GET_LIST_CATEGORIES_FAILURE';
export const GET_LIST_CATEGORIES_SUCCESS = 'GET_LIST_CATEGORIES_SUCCESS';

export const EXIT_CATEGORY = 'EXIT_CATEGORY';

export const CHANGE_PLUS_KEYWORDS_REQUEST = 'CHANGE_PLUS_KEYWORDS_REQUEST';
export const CHANGE_PLUS_KEYWORDS_FAILURE = 'CHANGE_PLUS_KEYWORDS_FAILURE';
export const CHANGE_PLUS_KEYWORDS_SUCCESS = 'CHANGE_PLUS_KEYWORDS_SUCCESS';

export const CHANGE_ABOUT_CATEGORY_REQUEST = 'CHANGE_ABOUT_CATEGORY_REQUEST';
export const CHANGE_ABOUT_CATEGORY_FAILURE = 'CHANGE_ABOUT_CATEGORY_FAILURE';
export const CHANGE_ABOUT_CATEGORY_SUCCESS = 'CHANGE_ABOUT_CATEGORY_SUCCESS';

export const CHANGE_MAIL_DATA_REQUEST = 'CHANGE_MAIL_DATA_REQUEST';
export const CHANGE_MAIL_DATA_FAILURE = 'CHANGE_MAIL_DATA_FAILURE';
export const CHANGE_MAIL_DATA_SUCCESS = 'CHANGE_MAIL_DATA_SUCCESS';

export const CHANGE_YANDEX_METRIKA_ID_REQUEST = 'CHANGE_YANDEX_METRIKA_ID_REQUEST';
export const CHANGE_YANDEX_METRIKA_ID_FAILURE = 'CHANGE_YANDEX_METRIKA_ID_FAILURE';
export const CHANGE_YANDEX_METRIKA_ID_SUCCESS = 'CHANGE_YANDEX_METRIKA_ID_SUCCESS';

export const CHANGE_USER_TARIFF_REQUEST = 'CHANGE_USER_TARIFF_REQUEST';
export const CHANGE_USER_TARIFF_FAILURE = 'CHANGE_USER_TARIFF_FAILURE';
export const CHANGE_USER_TARIFF_SUCCESS = 'CHANGE_USER_TARIFF_SUCCESS';

export const GET_GEO_CITIES_REQUEST = 'GET_GEO_CITIES_REQUEST';
export const GET_GEO_CITIES_FAILURE = 'GET_GEO_CITIES_FAILURE';
export const GET_GEO_CITIES_SUCCESS = 'GET_GEO_CITIES_SUCCESS';

export const CHANGE_NOTE_REQUEST = 'CHANGE_NOTE_REQUEST';
export const CHANGE_NOTE_FAILURE = 'CHANGE_NOTE_FAILURE';
export const CHANGE_NOTE_SUCCESS = 'CHANGE_NOTE_SUCCESS';

export const HIDE_ORDER_REQUEST = 'HIDE_ORDER_REQUEST';
export const HIDE_ORDER_FAILURE = 'HIDE_ORDER_FAILURE';
export const HIDE_ORDER_SUCCESS = 'HIDE_ORDER_SUCCESS';

export const APPEAL_TO_SUPPORT_REQUEST = 'APPEAL_TO_SUPPORT_REQUEST';
export const APPEAL_TO_SUPPORT_FAILURE = 'APPEAL_TO_SUPPORT_FAILURE';
export const APPEAL_TO_SUPPORT_SUCCESS = 'APPEAL_TO_SUPPORT_SUCCESS';

export const GET_GEO_MARKERS_REQUEST = 'GET_GEO_MARKERS_REQUEST';
export const GET_GEO_MARKERS_FAILURE = 'GET_GEO_MARKERS_FAILURE';
export const GET_GEO_MARKERS_SUCCESS = 'GET_GEO_MARKERS_SUCCESS';

export const GET_OUTPUTS_LIST_REQUEST = 'GET_OUTPUTS_LIST_REQUEST';
export const GET_OUTPUTS_LIST_FAILURE = 'GET_OUTPUTS_LIST_FAILURE';
export const GET_OUTPUTS_LIST_SUCCESS = 'GET_OUTPUTS_LIST_SUCCESS';

export const GET_OUTPUT_CHANGE_STATUS_REQUEST = 'GET_OUTPUT_CHANGE_STATUS_REQUEST';
export const GET_OUTPUT_CHANGE_STATUS_FAILURE = 'GET_OUTPUT_CHANGE_STATUS_FAILURE';
export const GET_OUTPUT_CHANGE_STATUS_SUCCESS = 'GET_OUTPUT_CHANGE_STATUS_SUCCESS';

export const ADD_FREE_ORDER_REQUEST = 'ADD_FREE_ORDER_REQUEST';
export const ADD_FREE_ORDER_FAILURE = 'ADD_FREE_ORDER_FAILURE';
export const ADD_FREE_ORDER_SUCCESS = 'ADD_FREE_ORDER_SUCCESS';

export const GET_WORKERS_LIST_REQUEST = 'GET_WORKERS_LIST_REQUEST';
export const GET_WORKERS_LIST_FAILURE = 'GET_WORKERS_LIST_FAILURE';
export const GET_WORKERS_LIST_SUCCESS = 'GET_WORKERS_LIST_SUCCESS';

export const GET_WORKERS_INFO_REQUEST = 'GET_WORKERS_INFO_REQUEST';
export const GET_WORKERS_INFO_FAILURE = 'GET_WORKERS_INFO_FAILURE';
export const GET_WORKERS_INFO_SUCCESS = 'GET_WORKERS_INFO_SUCCESS';

export const DELETE_AVATAR_REQUEST = 'DELETE_AVATAR_REQUEST';
export const DELETE_AVATAR_FAILURE = 'DELETE_AVATAR_FAILURE';
export const DELETE_AVATAR_SUCCESS = 'DELETE_AVATAR_SUCCESS';

export const SEND_UPDATE_AVATAR_REQUEST = 'SEND_UPDATE_AVATAR_REQUEST';
export const SEND_UPDATE_AVATAR_FAILURE = 'SEND_UPDATE_AVATAR_FAILURE';
export const SEND_UPDATE_AVATAR_SUCCESS = 'SEND_UPDATE_AVATAR_SUCCESS';

export const CHANGE_STATUS_IN_REQUEST = 'CHANGE_STATUS_IN_REQUEST';
export const CHANGE_STATUS_IN_FAILURE = 'CHANGE_STATUS_IN_FAILURE';
export const CHANGE_STATUS_IN_SUCCESS = 'CHANGE_STATUS_IN_SUCCESS';

export const CHANGE_STATUS_REQUEST_ON_WORK_REQUEST = 'CHANGE_STATUS_REQUEST_ON_WORK_REQUEST';
export const CHANGE_STATUS_REQUEST_ON_WORK_FAILURE = 'CHANGE_STATUS_REQUEST_ON_WORK_FAILURE';
export const CHANGE_STATUS_REQUEST_ON_WORK_SUCCESS = 'CHANGE_STATUS_REQUEST_ON_WORK_SUCCESS';

export const ADD_RATING_REQUEST = 'ADD_RATING_REQUEST';
export const ADD_RATING_FAILURE = 'ADD_RATING_FAILURE';
export const ADD_RATING_SUCCESS = 'ADD_RATING_SUCCESS';


export const ADD_PHONE_TAG_REQUEST = 'ADD_PHONE_TAG_REQUEST';
export const ADD_PHONE_TAG_FAILURE = 'ADD_PHONE_TAG_FAILURE';
export const ADD_PHONE_TAG_SUCCESS = 'ADD_PHONE_TAG_SUCCESS';

export const GET_PHONE_INFO_REQUEST = 'GET_PHONE_INFO_REQUEST';
export const GET_PHONE_INFO_FAILURE = 'GET_PHONE_INFO_FAILURE';
export const GET_PHONE_INFO_SUCCESS = 'GET_PHONE_INFO_SUCCESS';

export const ADD_PHONE_NUMBER_REQUEST = 'ADD_PHONE_NUMBER_REQUEST';
export const ADD_PHONE_NUMBER_FAILURE = 'ADD_PHONE_NUMBER_FAILURE';
export const ADD_PHONE_NUMBER_SUCCESS = 'ADD_PHONE_NUMBER_SUCCESS';

export const ADD_PHONE_COMPLAINT_REQUEST = 'ADD_PHONE_COMPLAINT_REQUEST';
export const ADD_PHONE_COMPLAINT_FAILURE = 'ADD_PHONE_COMPLAINT_FAILURE';
export const ADD_PHONE_COMPLAINT_SUCCESS = 'ADD_PHONE_COMPLAINT_SUCCESS';

export const ADD_PHONE_QUESTION_REQUEST = 'ADD_PHONE_QUESTION_REQUEST';
export const ADD_PHONE_QUESTION_FAILURE = 'ADD_PHONE_QUESTION_FAILURE';
export const ADD_PHONE_QUESTION_SUCCESS = 'ADD_PHONE_QUESTION_SUCCESS';

export const GET_PHONE_LIST_REQUEST = 'GET_PHONE_LIST_REQUEST';
export const GET_PHONE_LIST_FAILURE = 'GET_PHONE_LIST_FAILURE';
export const GET_PHONE_LIST_SUCCESS = 'GET_PHONE_LIST_SUCCESS';

export const GET_ADDITIONALS_TAGS_REQUEST = 'GET_ADDITIONALS_TAGS_REQUEST';
export const GET_ADDITIONALS_TAGS_FAILURE = 'GET_ADDITIONALS_TAGS_FAILURE';
export const GET_ADDITIONALS_TAGS_SUCCESS = 'GET_ADDITIONALS_TAGS_SUCCESS';

export const GET_ADMIN_ADDITIONALS_TAGS_REQUEST = 'GET_ADMIN_ADDITIONALS_TAGS_REQUEST';
export const GET_ADMIN_ADDITIONALS_TAGS_FAILURE = 'GET_ADMIN_ADDITIONALS_TAGS_FAILURE';
export const GET_ADMIN_ADDITIONALS_TAGS_SUCCESS = 'GET_ADMIN_ADDITIONALS_TAGS_SUCCESS';

export const DELETE_ADMIN_TAG_REQUEST = 'DELETE_ADMIN_TAG_REQUEST';
export const DELETE_ADMIN_TAG_FAILURE = 'DELETE_ADMIN_TAG_FAILURE';
export const DELETE_ADMIN_TAG_SUCCESS = 'DELETE_ADMIN_TAG_SUCCESS';

export const DELETE_ADMIN_PHONE_REQUEST = 'DELETE_ADMIN_PHONE_REQUEST';
export const DELETE_ADMIN_PHONE_FAILURE = 'DELETE_ADMIN_PHONE_FAILURE';
export const DELETE_ADMIN_PHONE_SUCCESS = 'DELETE_ADMIN_PHONE_SUCCESS';

export const IMPORT_ADMIN_FILE_REQUEST = 'IMPORT_ADMIN_FILE_REQUEST';
export const IMPORT_ADMIN_FILE_FAILURE = 'IMPORT_ADMIN_FILE_FAILURE';
export const IMPORT_ADMIN_FILE_SUCCESS = 'IMPORT_ADMIN_FILE_SUCCESS';

export const SEND_IMPORT_DATA_REQUEST = 'SEND_IMPORT_DATA_REQUEST';
export const SEND_IMPORT_DATA_FAILURE = 'SEND_IMPORT_DATA_FAILURE';
export const SEND_IMPORT_DATA_SUCCESS = 'SEND_IMPORT_DATA_SUCCESS';

export const GET_COMPLAINT_LIST_REQUEST = 'GET_COMPLAINT_LIST_REQUEST';
export const GET_COMPLAINT_LIST_FAILURE = 'GET_COMPLAINT_LIST_FAILURE';
export const GET_COMPLAINT_LIST_SUCCESS = 'GET_COMPLAINT_LIST_SUCCESS';

export const GET_QUESTION_LIST_REQUEST = 'GET_QUESTION_LIST_REQUEST';
export const GET_QUESTION_LIST_FAILURE = 'GET_QUESTION_LIST_FAILURE';
export const GET_QUESTION_LIST_SUCCESS = 'GET_QUESTION_LIST_SUCCESS';

export const DELETE_QUESTION_REQUEST = 'DELETE_QUESTION_REQUEST';
export const DELETE_QUESTION_FAILURE = 'DELETE_QUESTION_FAILURE';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';

export const DELETE_COMPLAINT_REQUEST = 'DELETE_COMPLAINT_REQUEST';
export const DELETE_COMPLAINT_FAILURE = 'DELETE_COMPLAINT_FAILURE';
export const DELETE_COMPLAINT_SUCCESS = 'DELETE_COMPLAINT_SUCCESS';