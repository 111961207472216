import React, { Component } from 'react'
import { Redirect } from 'react-router'

import Phone from './phone'
import Login from './login'
//import LoginKey from './loginKey'
import Recover from './recover'
import Register from './register'

//import SelectCategory from '../cabinet/selectCategory'
//import Orders from '../cabinet/orders'

class Auth extends Component {

	render() {
		let pageComponent;

		switch(this.props.match.params.page) {
			case "phone":
				pageComponent = <Phone/>;
				break;
			case "login":
				pageComponent = <Login/>;
				break;
			case "recover":
				pageComponent = <Recover/>;
				break;
			case "register":
				pageComponent = <Register/>;
				break;
			default: 
				return <Redirect to="/auth/phone"/>;
		}

		return (
			<div className="not-authorized">
				{/*<div className="left">
					{Config.demoBuild ?
						<a href={Config.mainSiteUrl} className="logo row">
							<img src={`./img/logo-white.svg`} className="logo-white" alt=""/>
							<img src={`./img/logo-blue.svg`} className="logo-blue" alt=""/>
							<div className="text">{Config.projectName}</div>
						</a>
					:
						<a href={Config.mainSiteUrl} className="logo full">
							<img src={`./img/logo-white-full.svg`} className="logo-white-full" alt=""/>
							<img src={`./img/logo-full.svg`} className="logo-full" alt=""/>
						</a>
					}
					<img src={`./img/auth/all-circles.svg`} className="all-circles" alt=""/>
				</div>
				<div className="right">
					{pageComponent}
				</div>*/}
				<div className="full">
					{pageComponent}
				</div>
			</div>
		)
	}
}

export default Auth