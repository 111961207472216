import React from 'react'
import { connect } from 'react-redux'

import ModalDeleteTag from '../modals/deleteTag'
import ModalDeletePhone from '../modals/deletePhone'

import './index.scss'

class Phone extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			tagMoreShow: true,
			expandedKeys: [],
			countViewsTags: 5,
			modalDeleteTagId: null,
			modalDeletePhoneShow: false,
		};
	}

	sklonenie(number, txt) {
	    var cases = [2, 0, 1, 1, 1, 2];
	    return txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
	}

	getAdditionalTags(number) {
		this.props.getAdditionalTags({number: number})
		this.setState({tagMoreShow: false})
	}

	render() {
		let {
			tagMoreShow,
			expandedKeys,
			countViewsTags,
			modalDeleteTagId,
			modalDeletePhoneShow,
		} = this.state;

		let { 
			tags,
			number,
			count_tags,
			count_views,
		} = this.props.data;

		let tagsList = () => tags ? tags.map((item, key) => {
			return (
				<div 
					key={key}
					className={`tag ${expandedKeys.indexOf(key) > -1 ? 'expanded' : ''}`}
					onClick={() => this.setState({expandedKeys: [...expandedKeys, key]})}
				>
					<span className="sharp">#</span>
					<span className="name">{item.name}</span>
					{this.props.adminAccess ? 
						<div 
							className="deleteTag" 
							onClick={() => this.setState({modalDeleteTagId: item.id})}
						>×</div> 
					: false}
				</div>
			)
		}) : false;

		return (
			<div className="phone">
				<div className="info">

					<div className="main row">
						<h1 className="number">{number}</h1>
						<div className="views">
							{count_views}
							<img src="./img/icons/eye.png" alt=""/>
						</div>
					</div>

					<div className="tags">
						{tagsList()}
						
						{+count_tags > countViewsTags && tags.length <= countViewsTags && tagMoreShow ? 
							<div className="tag more" onClick={() => this.getAdditionalTags(number)}>
								Еще {count_tags - countViewsTags} {this.sklonenie(+count_tags - countViewsTags, ['тег', 'тега', 'тегов'])}
							</div> 
						: false}

						{!+count_tags ? <h1>Упс.. По этому номеру<br/> еще нет тегов</h1> : false}
					</div>

					{this.props.adminAccess ? <button className="deletePhone" onClick={() => this.setState({modalDeletePhoneShow: true})}>Удалить номер</button> : false}
					
				</div>

				{modalDeletePhoneShow ? <ModalDeletePhone {...this.props} close={() => this.setState({modalDeletePhoneShow: false})} /> : false}
				{modalDeleteTagId ? <ModalDeleteTag {...this.props} tagId={modalDeleteTagId} close={() => this.setState({modalDeleteTagId: null})} /> : false}
			</div>
		)
	}

}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
	
})

export default connect(mapStateToProps, mapDispatchToProps)(Phone);