import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Cabinet from '../'
import PhoneBlock from './phoneBlock'
import ModalAddTag from '../modals/addTag'
import ModalAddComplaint from '../modals/addComplaint'

import Preload from '../../elements/preload'

import { getInfo, getAdditionalTags } from '../../../actions/phone'

import NotFoundPhone from '../notFoundPhone'

import './index.scss'

class Phone extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			modalAddTagShow: false,
			modalAddComplaintShow: false
		};
	}

	getInfo() {
		let { number } = this.props.match.params;

		this.props.getInfo({
			number: number,
			add_view: true,
		});
	}

	componentDidMount() {
		this.getInfo();
	}

	componentDidUpdate(prevProps) {
		if(this.props.match.params.number !== prevProps.match.params.number) {
			this.getInfo();
		}

		if(this.props.phone.id || this.props.phone.isLoaded) {
			let title = `Звонят с номера ${this.props.phone.number}`;
			let description = `Посмотрите теги пользователей о номере ${this.props.phone.number} или добавьте свой.`;

			document.title = title;
			document.querySelector('meta[property="title"]').content = title;
			document.querySelector('meta[property="description"]').content = description;
		}
	}

	componentWillUnmount() {
		this.props.phone.id = null;
		this.props.phone.isLoaded = null;

		document.title = document.querySelector('meta[property="source:title"]').content;;
		document.querySelector('meta[property="title"]').content = document.querySelector('meta[property="source:title"]').content;
		document.querySelector('meta[property="description"]').content = document.querySelector('meta[property="source:description"]').content;
	}

	render() {
		if((!this.props.phone.id || !this.props.phone.isLoaded) && this.props.phone.number !== 'empty') return <Preload/>;

		let {
			modalAddTagShow,
			modalAddComplaintShow,
		} = this.state;

		return (
			<Cabinet title="Информация о номере" invert={!!this.props.phone.id} {...this.props}>

				{this.props.phone.number === 'empty' ? 
					<NotFoundPhone/>
				: 
					<div className="content page-phone">
						<Link to='/' className="btn btn-blue btn-back">Назад</Link>

						<PhoneBlock data={this.props.phone} getAdditionalTags={(data) => this.props.getAdditionalTags(data)}/>

						<div className="buttons row">
							<button className="btn btn-blue add-tag" onClick={() => this.setState({modalAddTagShow: true})}>
								# Добавить тег
							</button>
							<button className="btn btn-transparent complain" onClick={() => this.setState({modalAddComplaintShow: true})}>
								Пожаловаться
							</button>
						</div>

						{modalAddTagShow ? <ModalAddTag {...this.props} close={() => this.setState({modalAddTagShow: false})} /> : false}
						{modalAddComplaintShow ? <ModalAddComplaint {...this.props} close={() => this.setState({modalAddComplaintShow: false})} /> : false}
					</div>
				}

			</Cabinet>
		)
	}

}

const mapStateToProps = (state) => ({
	phone: state.phone
})

const mapDispatchToProps = (dispatch) => ({
	getInfo: (data) => dispatch(getInfo(data)),
	getAdditionalTags: (data) => dispatch(getAdditionalTags(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Phone);