import React from 'react'
import Popup from 'reactjs-popup'
import { connect } from 'react-redux'

import { deleteComplaint } from '../../../../actions/admin'

class ModalDeleteComplaint extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			phone: ""
		};
	}

	componentDidUpdate() {
		if(this.props.phone.newNumber) {

			delete(this.props.phone.newNumber);

			this.setState({phone: ""});
			this.close();
		}
	}

	componentWillUnmount() {
		this.setState({phone: ""});
	}

	close() {
		this.setState({phone: ""});

		this.props.close();
	}

	deleteComplaint() {
		//let { number } = this.props.data;

		this.props.deleteComplaint({
			complaint_id: this.props.complaintId,
		});

		this.close();
	}

	render() {
		return (
			<Popup 
				modal
				open={true}
				onClose={() => this.close()}
			>
				{close => (
					<div className="modal modal-add-phone">

						<div className="close" onClick={() => close()}>
							×
						</div>

						<div className="content">
							<div className="title">Вы точно хотите<br/> удалить жалобу?</div>
							<button 
								className="btn btn-transparent"
								onClick={() => this.deleteComplaint()}
							>
								Да, удалить
							</button>
							<button 
								className="btn btn-transparent"
								onClick={() => close()}
							>
								Отменить
							</button>
						</div>

					</div>
				)}
			</Popup>
		)
	}

}

const mapStateToProps = (state) => ({
	phone: state.phone
})

const mapDispatchToProps = (dispatch) => ({
	deleteComplaint: (data) => dispatch(deleteComplaint(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteComplaint);