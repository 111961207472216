import React from 'react'
import { ReactSVG } from 'react-svg'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Redirect } from 'react-router'

import AdminLogin from './login'

import PageUsers from './pageUsers'
import PagePhones from './pagePhones'
import PageQuestions from './pageQuestions'
import PageComplaints from './pageComplaints'

import { getInfo } from '../../../actions/admin'
import { logout } from '../../../actions/authLogout'

import './index.scss'

class Admin extends React.Component {

	componentDidMount() {
		if(localStorage.token) this.props.getInfo();
	}

	logout(e) {
		e.preventDefault();

		this.props.logout();
	}

	mobileOpenMenu() {
		if(localStorage.mobileVersion) document.querySelector('.sidebar').style = 'display: flex !important';
	}

	mobileCloseMenu(e) {
		if(e.currentTarget.className === "active") e.preventDefault();

		if(localStorage.mobileVersion) document.querySelector('.sidebar').style = 'display: none !important';
	}

	render() {
		if(this.props.authLogout.success) {
			delete(this.props.authLogout.success)
			return <Redirect to='/'/>;
		}

		if(!localStorage.token && !localStorage.isAdmin) return <AdminLogin />;

		let pageComponent;

		switch(this.props.match.params.section) {
			case "phones":
				pageComponent = <PagePhones {...this.props}/>;
				break;
			case "complaints":
				pageComponent = <PageComplaints {...this.props}/>;
				break;
			case "questions":
				pageComponent = <PageQuestions {...this.props}/>;
				break;
			case "users":
				pageComponent = <PageUsers {...this.props}/>;
				break;
			default: 
				return <Redirect to="/admin/phones"/>;
		}

		return (
			<div className="admin">

				<div className="header">
					<ReactSVG src={`./img/icons/menu.svg`} className="svg-icon mobile-open-menu" onClick={() => this.mobileOpenMenu()}/>
				</div>

				<div className="sidebar">
					<ReactSVG src={`./img/icons/close.svg`} className="svg-icon mobile-close-menu" onClick={(e) => this.mobileCloseMenu(e)}/>

					<NavLink to='/admin/phones' onClick={(e) => this.mobileCloseMenu(e)}>Номера телефонов</NavLink>
					<NavLink to='/admin/complaints' onClick={(e) => this.mobileCloseMenu(e)}>Жалобы</NavLink>
					<NavLink to='/admin/questions' onClick={(e) => this.mobileCloseMenu(e)}>Обращения</NavLink>
					<NavLink to='/admin/users' onClick={(e) => this.mobileCloseMenu(e)}>Пользователи</NavLink>
					<a href="/" onClick={(e) => this.logout(e)}>Выход</a>
				</div>

				{pageComponent}
			</div>
		)
	}

}

const mapStateToProps = (state) => ({
	authLogout: state.authLogout
})

const mapDispatchToProps = (dispatch) => ({
	logout: (data) => dispatch(logout(data)),
	getInfo: (data) => dispatch(getInfo(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Admin);