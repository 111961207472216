import React from 'react';

import './index.scss';

export default class Preload extends React.Component {

	render() {
		let { color, inBlock } = this.props;

		return ( 
			<div 
				className={`preload_back ${inBlock ? 'inBlock' : ''}`} 
				style={color ? {color: color} : null}
			>
				<div style={color ? {color: color} : null}/>
				<div style={color ? {color: color} : null}/>
			</div>
		);
	}
}