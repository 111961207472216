import React from 'react'

import Preload from '../elements/preload'

import Header from './header'
import Footer from './footer'

import './index.scss'

class Cabinet extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			
		};
	}

	componentDidMount() {
		
	}

	goBack(e){
		e.preventDefault();
		this.props.history.goBack();
	}


	render() {
		let {
			//title,
			invert,
			isLoaded = true
		} = this.props;

		return (
			<div className={`cabinet ${invert ? 'invert' : ''}`}>
				<Header />

				{!isLoaded ? <Preload/> : this.props.children}

				<Footer />
			</div>
		)
	}

}

export default Cabinet;