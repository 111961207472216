import React from 'react'
import { connect } from 'react-redux'

import ModalAddQuestion from '../modals/addQuestion'

import './index.scss'

class Footer extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			modalAddQuestionShow: false
		};
	}

	render() {
		let {
			modalAddQuestionShow,
		} = this.state;

		return (
			<div className="footer">
				<div className="copy">
					© 2022 Все права защищены.
				</div>

				<button className="write-to-us" onClick={() => this.setState({modalAddQuestionShow: true})}>
					Написать нам
				</button>

				{modalAddQuestionShow ? <ModalAddQuestion close={() => this.setState({modalAddQuestionShow: false})} /> : false}
			</div>
		)
	}

}

const mapStateToProps = (state) => ({
	
})

const mapDispatchToProps = (dispatch) => ({
	
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer);