import { 
	SHOW_MODAL_CROP,
	HIDE_MODAL_CROP,
	UPDATE_APP_REQUEST,
	UPDATE_APP_FAILURE,
	UPDATE_APP_SUCCESS,
	ENABLE_ALERT_ERROR,
	ENABLE_ALERT_SUCCESS,
	GET_APP_INFO_REQUEST,
	GET_APP_INFO_FAILURE,
	GET_APP_INFO_SUCCESS,
	CHANGE_STATUS_IN_REQUEST,
	CHANGE_STATUS_IN_FAILURE,
	CHANGE_STATUS_IN_SUCCESS,
} from '../actions/types';

const initialState = { 
	data: {},
	workers: [],
	user_active_order: null,
	worker_active_order: null
};

export default function(state = initialState, action ) {
	switch(action.type) {
		case ENABLE_ALERT_ERROR:
			return {
				...state, 
				alert: action.payload
			}

		case ENABLE_ALERT_SUCCESS:
			
			return {
				...state, 
				alert: action.payload
			}

		case SHOW_MODAL_CROP:
			return {
				...state, 
				...action.payload,
			}
		case HIDE_MODAL_CROP:
			return {
				...state, 
				...action.payload,
			}

		case GET_APP_INFO_REQUEST:
			return {
				...state
			}
		case GET_APP_INFO_FAILURE:
			return {
				...state, 
				...action.payload,
			}
		case GET_APP_INFO_SUCCESS:
			return {
				...state, 
				...action.payload,
			}

		case UPDATE_APP_REQUEST:
			return {
				...state
			}
		case UPDATE_APP_FAILURE:
			return {
				...state, 
				...action.payload,
			}
		case UPDATE_APP_SUCCESS:
			return {
				...state, 
				...action.payload,
			}

		case CHANGE_STATUS_IN_REQUEST:
			return {
				...state
			}
		case CHANGE_STATUS_IN_FAILURE:
			return {
				...state, 
				...action.payload,
			}
		case CHANGE_STATUS_IN_SUCCESS:
			return {
				...state, 
				...action.payload,
				in_work: +action.payload.in_work
			}

		default: 
			return state;
	}
}