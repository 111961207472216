import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Cabinet from '../'

import './index.scss'

class Search extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			phone: ""
		};
	}

	render() {
		let { phone } = this.state;

		let toNumber = string => string ? string.replace(/[^0-9]/g,"") : "";

		return (
			<Cabinet title="Поиск номера" {...this.props}>
				<div className="content search">

					<h1>Узнайте, кто вам<br/>звонит</h1>

					<div className="phone-form">
						<input
							className="phone-input"
							placeholder="Например, «79370000000»"
							value={toNumber(phone)}
							onChange={(e) => this.setState({phone: toNumber(e.target.value)})}
						/>
						<Link 
							className="btn btn-blue start-search" 
							to={phone.length ? `/${phone}` : '#'}
						>
							Найти
						</Link>
					</div>

				</div>
			</Cabinet>
		)
	}

}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Search);