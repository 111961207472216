import React from 'react'
import Popup from 'reactjs-popup'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import { addNumber } from '../../../../actions/phone'

class ModalAddPhone extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			phone: ""
		};
	}

	componentDidUpdate() {
		if(this.props.phone.newNumber) {

			delete(this.props.phone.newNumber);

			this.setState({phone: ""});
			this.close();
		}
	}

	componentWillUnmount() {
		this.setState({phone: ""});
	}

	close() {
		this.setState({phone: ""});

		this.props.close();
	}

	render() {
		let { phone } = this.state;

		if(this.props.phone.newNumber) return <Redirect to={this.props.redirect ? this.props.redirect : `/${this.props.phone.number}`} push/>;

		let toNumber = string => string ? string.replace(/[^0-9]/g,"") : "";

		return (
			<Popup 
				modal
				open={true}
				onClose={() => this.close()}
			>
				{close => (
					<div className="modal modal-add-phone">

						<div className="close" onClick={() => close()}>
							×
						</div>

						<div className="content">
							<input
								className="phone-input"
								placeholder="79370000000"
								value={toNumber(phone)}
								onChange={(e) => this.setState({phone: toNumber(e.target.value)})}
							/>
							<button 
								className="btn btn-transparent"
								onClick={() => this.props.addNumber({number: phone})}
							>
								Добавить номер
							</button>
						</div>

					</div>
				)}
			</Popup>
		)
	}

}

const mapStateToProps = (state) => ({
	phone: state.phone
})

const mapDispatchToProps = (dispatch) => ({
	addNumber: (data) => dispatch(addNumber(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddPhone);