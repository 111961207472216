import React from 'react'
import { connect } from 'react-redux'
import AvatarEditor from 'react-avatar-editor'

import './index.scss';

class ModalCrop extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			show: false
		};
	}

	componentDidUpdate() {
		if(!this.state.show && this.props.app.modalCrop) {

			const {
				file,
				db_id,
				width,
				height,
				border,
				updateImage,
				borderRadius,
			} = this.props.app.modalCrop;

			this.setState({
				show: true,
				scale: 1.5,
				file: file,
				db_id: db_id,
				width: width,
				height: height,
				border: border,
				updateImage: updateImage,
				borderRadius: borderRadius
			});
		}

		if(this.props.close) this.setState({show: false});
	}

	closeFunc() {
		if(this.props.closeFunc) this.props.closeFunc();
		this.props.app.modalCrop = null;
		this.setState({show: false});
	}

	render() {
		if(!this.state.show) return false;

		const { 
			file,
			scale,
			width,
			height,
			border,
			borderRadius
		} = this.state;

		return ( 
			<div className="modalCrop">
				<AvatarEditor
					ref={ref => this.AvatarEditor = ref}
					image={file}
					width={width}
					height={height}
					border={border}
					borderRadius={borderRadius}
					color={[255, 255, 255, 0.8]}
					scale={scale}
					rotate={0}
				/>
				<input type="range" min="1" step="0.1" max="2" multiple 
					className="image_editor_range"
					defaultValue={scale} 
					onChange={(e) => this.setState({scale: +e.target.value})}
				/>
				<div className="icons">
					<div className="fa fa-times" onClick={() => this.closeFunc()}/>
					<div className="fa fa-check" onClick={() => this.onClickSave()}/>
				</div>
			</div>
		);
	}

	onClickSave() {
		const canvas = this.AvatarEditor.getImage()
		const dataURL = canvas.toDataURL();

		this.state.updateImage(dataURL);

		this.closeFunc();
	}
}

const mapStateToProps = (state) => ({
	app: state.app,
})

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalCrop);