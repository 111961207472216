import React from 'react'
import { connect } from 'react-redux'

import PhoneBlock from '../phone/phoneBlock'
import ModalAddPhone from '../modals/addPhone'
import ModalImportFile from '../modals/importFile'

import { 
	getPhonesList,
	getAdminAdditionalTags
} from '../../../actions/admin'

import CabinetTable from '../cabinetTable'

class AdminPhones extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			searchPhone: '',
			modalAddPhoneShow: false,
			modalImportFileShow: false
		};
	}

	searchPhone() {
		let { searchPhone } = this.state;

		this.props.getPhonesList({
			search_phone: searchPhone
		});
	}

	render() {
		let {
			searchPhone,
			modalAddPhoneShow,
			modalImportFileShow
		} = this.state;

		let {
			email,
			phones
		} = this.props.admin;

		let toNumber = string => string ? string.replace(/[^0-9]/g,"") : "";

		return (
			<div className="admin-page-phones content">

				<div className="head">
					<button 
						className="btn btn-blue add-phone"
						onClick={() => this.setState({modalAddPhoneShow: true})}
					>
						+ Добавить номер
					</button>

					<button 
						className="btn btn-blue add-phone"
						onClick={() => this.setState({modalImportFileShow: true})}
					>
						Импорт
					</button>

					<div className="email">
						{email}
					</div>
				</div>

				<div className="search-phone">
					<input
						placeholder="Например, «79370000000»"
						value={toNumber(searchPhone)}
						onChange={(e) => this.setState({searchPhone: toNumber(e.target.value)})}
					/>
					<button 
						className="btn btn-blue start-search"
						onClick={() => this.searchPhone()}
					>
						Найти
					</button>
				</div>

				<div className="indicator">
					Показано: <b>{phones.list.length}</b> из <b>{phones.count || 0}</b>
				</div>

				<CabinetTable 
					{...this.props}
					filter={false}
					isLoaded={phones.isLoaded}
					countItems={phones.count}
					itemsCountPerPage={100}
					tbody={this.tableBody(phones.list)}
					requestToServer={params => this.props.getPhonesList(params)}
				/>

				{modalAddPhoneShow ? 
					<ModalAddPhone 
						redirect="/admin"
						close={() => this.setState({modalAddPhoneShow: false})} 
					/> 
				: false}

				{modalImportFileShow ?
					<ModalImportFile
						close={() => this.setState({modalImportFileShow: false})} 
					/>
				: false}
			</div>
		)
	}

	tableBody(list) {
		if(!list.length) return false;

		let readyList = list.map((item, key) => {
			return (
				<tr key={key}>
					<td>
						<PhoneBlock 
							data={item}
							adminAccess
							getAdditionalTags={(data) => this.props.getAdminAdditionalTags(data)}
						/>
					</td>
				</tr>
			)
		});

		return readyList;
	}

}

const mapStateToProps = (state) => ({
	admin: state.admin
})

const mapDispatchToProps = (dispatch) => ({
	getPhonesList: (data) => dispatch(getPhonesList(data)),
	getAdminAdditionalTags: (data) => dispatch(getAdminAdditionalTags(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminPhones);