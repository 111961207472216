import React from 'react'
import { ReactSVG } from 'react-svg'

class Toggle extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			open: false,
			title: props.title
		};

	}

	componentDidUpdate() {
		if(this.state.title !== this.props.title) this.setState({title: this.props.title})
	}

	onClickTitle(id) {
		let { onClickTitle } = this.props;

		onClickTitle(id);
	}

	render() {
		let { title, open } = this.state;
		let { input, className, placeholder } = this.props;

		return (
			<div className={`toggle ${className || ""}`}>
				<button onClick={() => this.setState({open: !open})}>
					<div className="title">{title ? title : placeholder}</div>
					<ReactSVG src={`./img/icons/chevron-${open ? 'up' : 'down'}.svg`} className="svg-icon"/> 
				</button>

				{open ? 
					<div className="content">
						{input}
						<div onClick={() => this.setState({open: false})}>
							{this.props.children}
						</div>
					</div>
				: false}

				{open ? <div className="layer-close" onClick={() => this.setState({open: false})}/> : false}
			</div>
		)
	}

}

export default Toggle;