import React from 'react'
import Moment from 'react-moment'
import { connect } from 'react-redux'

import { 
	userList,
	changeUserRules
} from '../../../actions/admin'

import { registerEmail } from '../../../actions/authRegister'

import CabinetTable from '../cabinetTable'

class Admin extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			editTariffId: 0
		};
	}

	changeField(e) {
		let { name, value, type } = e.target;

		this.setState({[name]: type === 'checkbox' ? !this.state[name] : value});
	}

	registerEmail() {
		const {
			emailNewUser,
			passwordNewUser
		} = this.state;

		this.props.registerEmail({
			email: emailNewUser,
			password: passwordNewUser,
			confirm_password: passwordNewUser,
		});
	}

	changeUserRules(status) {
		let { changeUserRulesEmail } = this.state;

		if(!changeUserRulesEmail) return false;

		this.props.changeUserRules({
			email: changeUserRulesEmail,
			status: status
		})
	}

	changeUserTariff(status) {
		let { 
			changeUserTariffId,
			changeUserTariffEmail
		} = this.state;

		this.props.changeUserTariff({
			status: status,
			email: changeUserTariffEmail,
			tariff_id: changeUserTariffId
		})
	}

	render() {
		let {
			email,
			users,
		} = this.props.admin;

		return (
			<div className="admin-page-users content">

				<div className="head">
					<div className="indicator">
						Показано: <b>{users.list.length}</b> из <b>{users.count || 0}</b>
					</div>
					<div className="email">
						{email}
					</div>
				</div>

				{users.isLoaded ?
					<div className="field">
						<div className="title">Создать пользователя</div>
						<input 
							name="emailNewUser" 
							pattern="[\s\d]+"
							className="value"
							placeholder="Email"
							onChange={e => this.changeField(e)}
						/>

						<input 
							name="passwordNewUser"
							className="value"
							placeholder="Пароль"
							onChange={e => this.changeField(e)}
						/>

						<div className="row-btn">
							<button className="btn btn-blue" onClick={(e) => this.registerEmail()}>
								Создать
							</button>
						</div>
					</div>
				: false}

				{users.isLoaded ?
					<div className="field">
						<div className="title">Выдать / Забрать права администратора</div>
						<input 
							name="changeUserRulesEmail" 
							type="tel"
							pattern="[\s\d]+"
							className="value"
							placeholder="Email"
							onChange={e => this.changeField(e)}
						/>

						<div className="row-btn">
							<button className="btn btn-blue" onClick={(e) => this.changeUserRules(1)}>
								Выдать
							</button>
							<button className="btn btn-blue" onClick={(e) => this.changeUserRules(0)}>
								Забрать
							</button>
						</div>
					</div>
				: false}

				<CabinetTable 
					{...this.props}
					filter={false}
					isLoaded={users.isLoaded}
					thead={
						<tr>
							<th>ID</th>
							<th>Email</th>
							<th>Дата регистрации</th>
						</tr>
					}
					countItems={users.count}
					tbody={this.tableBody(users.list)}
					requestToServer={params => this.props.userList(params)}
				/>

			</div>
		)
	}

	tableBody(list) {
		if(!list.length) return false;

		let readyList = list.map((item, key) => {
			let is_admin;

			switch(+item.is_admin) {
				case 0:
					is_admin = '';
					break;
				case 1:
					is_admin = 'Admin';
					break;
				default: 
					is_admin = '—';
					break;
			}

			return (
				<tr key={key}>
					<td>{item.id}</td>
					<td>
						<span>
							{item.email}
							<span className="role">{is_admin}</span>
						</span>
					</td>
					<td>
						<Moment locale="ru" format="DD.MM.YYYY HH:mm" unix local ago>{item.creation_time}</Moment>
					</td>
				</tr>
			)
		});

		return readyList;
	}

}

const mapStateToProps = (state) => ({
	admin: state.admin,
	payment: state.payment,
})

const mapDispatchToProps = (dispatch) => ({
	userList: (data) => dispatch(userList(data)),
	registerEmail: (data) => dispatch(registerEmail(data)),
	changeUserRules: (data) => dispatch(changeUserRules(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Admin);