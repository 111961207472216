import {
	SHOW_MODAL_CROP,
	UPDATE_APP_REQUEST,
	UPDATE_APP_FAILURE,
	UPDATE_APP_SUCCESS,
	GET_APP_INFO_REQUEST,
	GET_APP_INFO_FAILURE,
	GET_APP_INFO_SUCCESS,
	APPEAL_TO_SUPPORT_REQUEST,
	APPEAL_TO_SUPPORT_FAILURE,
	APPEAL_TO_SUPPORT_SUCCESS,
} from './types'
import config from '../config'
import { 
	handleError,
	serverError,
	handleSuccess
} from './handleActions'

export const getAppInfo = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/app/getInfo',
		data: data,
		request: res => {
			return { 
				type: GET_APP_INFO_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: GET_APP_INFO_FAILURE,
				payload: res
			}
		},
		success: res => {
			//if(res.support_chat) {
			if(!config.demoBuild) {
				// var s = document['createElement']('script');
				// s.type = 'text/javascript'; 
				// s.async = true; 
				// s.charset = 'utf-8';	
				// s.src = 'https://w.tb.ru/open-messenger/widget?wId=W-62AA35018CCA4D21A899BA929E034545'; 
				// var ss = document['getElementsByTagName']('script')[0]; 
				// if(ss) {
				// 	ss.parentNode.insertBefore(s, ss);
				// } else {
				// 	document.documentElement.firstChild.appendChild(s);
				// };
			}

			return { 
				type: GET_APP_INFO_SUCCESS,
				payload: res
			}
		}
	}))
}

export const updateApp = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/app/updateInfo',
		data: data,
		request: res => {
			return { 
				type: UPDATE_APP_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: UPDATE_APP_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: UPDATE_APP_SUCCESS,
				payload: res
			}
		},
		serverError: res => {
			
		}
	}))
}

export const appealToSupport = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/app/appealToSupport',
		data: data,
		request: res => {
			return { 
				type: APPEAL_TO_SUPPORT_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: APPEAL_TO_SUPPORT_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: APPEAL_TO_SUPPORT_SUCCESS,
				payload: res
			}
		}
	}))
}

export const fetchToAPI = (obj) => dispatch => {
	dispatch(obj.request(obj.data))

	fetch(obj.fullUrl ? obj.url : config.serverUrl + obj.url, {
		method: obj.method ? obj.method : 'POST',
		body: obj.formData ? 
			obj.formData : JSON.stringify({...obj.data, token: localStorage.token})
	})
	.then(res => res.json())
	.then(data => {
		if(data.error) {
			dispatch(handleError(data))
			if(obj.failure) dispatch(obj.failure(data))
			return false;
		}

		if(data.success && data.description) {
			dispatch(handleSuccess(data))
		}

		if(obj.success) dispatch(obj.success(data))
	})
	.catch(err => {
		if(obj.serverError) {
			obj.serverError();
			return false;
		}
		dispatch(serverError(err))
		console.log(obj)
	})
}

export const cleanLocalStorage = data => dispatch => {
	localStorage.removeItem('token');
	localStorage.removeItem('isAdmin');
	localStorage.removeItem('isPartner');
	localStorage.removeItem('isWorker');
}
export const createAuthLocalStorage = data => dispatch =>  {
	dispatch(cleanLocalStorage())
	if(data.token) localStorage.setItem('token', data.token);
	if(data.is_admin) localStorage.setItem('isAdmin', data.is_admin);
	if(data.is_partner) localStorage.setItem('isPartner', data.is_partner);
	if(data.is_worker) localStorage.setItem('isWorker', data.is_worker);
}

export const showModalCrop = (data) => {
	return {
		type: SHOW_MODAL_CROP,
		payload: {
			modalCrop: data
		}
	}
}