import React from 'react'
import Popup from 'reactjs-popup'
import { connect } from 'react-redux'

import { deleteQuestion } from '../../../../actions/admin'

class ModalDeleteQuestion extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			
		};
	}

	close() {
		this.props.close();
	}

	deleteQuestion() {
		this.props.deleteQuestion({
			question_id: this.props.questionId,
		});

		this.close();
	}

	render() {
		return (
			<Popup 
				modal
				open={true}
				onClose={() => this.close()}
			>
				{close => (
					<div className="modal modal-add-phone">

						<div className="close" onClick={() => close()}>
							×
						</div>

						<div className="content">
							<div className="title">Вы точно хотите<br/> удалить обращение?</div>
							<button 
								className="btn btn-transparent"
								onClick={() => this.deleteQuestion()}
							>
								Да, удалить
							</button>
							<button 
								className="btn btn-transparent"
								onClick={() => close()}
							>
								Отменить
							</button>
						</div>

					</div>
				)}
			</Popup>
		)
	}

}

const mapStateToProps = (state) => ({
	phone: state.phone
})

const mapDispatchToProps = (dispatch) => ({
	deleteQuestion: (data) => dispatch(deleteQuestion(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteQuestion);