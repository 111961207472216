import React from 'react'
import Popup from 'reactjs-popup'
import { connect } from 'react-redux'

import { importFile, sendImportData } from '../../../../actions/admin'

import Toggle from '../../../elements/toggle'

class ModalImportFile extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			file: null,
			selectedPhoneColumn: false,
			importManagementShow: false,
			columnImportManagement: null,
			toggleTitles: ['Не учитывать', 'Номер телефона', 'Тег'],
		};
	}

	componentDidUpdate() {
		let { selectedPhoneColumn, columnImportManagement } = this.state;

		let newSelectedPhoneColumn;

		for (var prop in columnImportManagement) {
			newSelectedPhoneColumn = columnImportManagement[prop] === 1;
			if(newSelectedPhoneColumn) break;
		}

		if(selectedPhoneColumn !== newSelectedPhoneColumn) {
			this.setState({selectedPhoneColumn: newSelectedPhoneColumn});
		}
	}

	componentWillUnmount() {
		delete(this.props.admin.importManagement);
	}

	close() {
		this.props.close();
	}

	importFile() {
		let { file } = this.state;

		if(!file) return false;

		this.props.importFile(file);

		this.setState({importManagementShow: true});
	}

	changeColumnImportManagement(column, key) {
		this.setState({
			columnImportManagement: {
				...this.state.columnImportManagement,
				[column]: key
			}
		})
	}

	sendImportData() {
		let { selectedPhoneColumn, columnImportManagement } = this.state;
		let { importManagement } = this.props.admin;

		if(!columnImportManagement || !selectedPhoneColumn) return false;

		let data = importManagement.list.map((row, rowKey) => {
			let newRow = {
				number: null,
				tags: []
			};

			row.map((column, columnKey) => {
				let columnPhone = columnImportManagement[columnKey] === 1;
				let columnTag = columnImportManagement[columnKey] === 2;

				newRow = {
					...newRow,
					...columnPhone && column ? {number: column} : {},
					...columnTag && column ? {tags: [...newRow.tags, column]} : {}
				}

				return true;
			});

			return newRow;
		});

		this.props.sendImportData({data: data});

		this.close();
	}


	render() {
		let { file, toggleTitles, importManagementShow, selectedPhoneColumn, columnImportManagement } = this.state;
		let { importManagement } = this.props.admin;

		let importManagementList = () => importManagement.list.map((row, rowKey) => 
			<div className="row" key={rowKey}>
				{row.map((column, columnKey) => <div className="column" key={columnKey}>{column}</div>)}
			</div>
		);

		let importManagementToggles = () => {
			let togglesList = [];

			for (let i = 0; i < importManagement.count_columns; i++) {
				togglesList.push(
					<Toggle key={i} className="column" title={toggleTitles[columnImportManagement ? +columnImportManagement[i] || 0 : 0]}>
						{toggleTitles.map((name, key) => 
							!selectedPhoneColumn || key !== 1 ?
								(
									<button 
										key={key}
										onClick={(e) => this.changeColumnImportManagement(i, key)}
									>{name}</button>
								) : false
						)}
					</Toggle>
				);
			}

			return togglesList;
		}

		return (
			<Popup 
				modal
				open={true}
				onClose={() => this.close()}
			>
				{close => importManagementShow ?
					<div className="modal full import-management">

						<div className="close" onClick={() => close()}>
							×
						</div>

						<div className="content">
							<div className="title">Импорт контактов</div>

							<div className="scroll">
								<div className="toggles row">
									{importManagement ? importManagementToggles() : false}
								</div>

								<div className="list">
									{importManagement ? importManagementList() : "Идет загрузка файла..."}
								</div>
							</div>

							{importManagement ?
								<div className="buttons">
									<button className="btn btn-transparent" onClick={() => close()}>
										Отменить
									</button>
									<button className="btn btn-transparent" onClick={() => this.sendImportData()}>
										Импортировать
									</button>
								</div>
							 : false}
						</div>

					</div>
				:
					<div className="modal modal-import-file">

						<div className="close" onClick={() => close()}>
							×
						</div>

						<div className="content">
							<div className="title">Выберите файл для<br/> импорта</div>

							<div className="input-file">
								<input 
									name="file" 
									type="file" 
									onChange={e => e.target.files[0] ? this.setState({file: e.target.files[0]}) : false}
								/>
								<button className={`btn btn-transparent ${file ? 'active' : ''}`}>
									{file ? file.name : 'Выбрать…'}
								</button>
							</div>

							<button 
								className="btn btn-transparent"
								onClick={() => this.importFile()}
							>
								Продолжить
							</button>
						</div>

					</div>
				}
			</Popup>
		)
	}

}

const mapStateToProps = (state) => ({
	admin: state.admin
})

const mapDispatchToProps = (dispatch) => ({
	importFile: (data) => dispatch(importFile(data)),
	sendImportData: (data) => dispatch(sendImportData(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalImportFile);