import React from 'react'
import Config from './config'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Auth from './components/auth'
import NotFound from './components/notFound'

import Phone from './components/cabinet/phone'
import Search from './components/cabinet/search'

import Admin from './components/cabinet/admin'

import ReactSwipe from './components/elements/reactSwipe'

import './app.scss'

class App extends React.Component {

	constructor(props) {
		super(props);

		this.state = {

		};

		var url_string = window.location.href;
		var url = new URL(url_string);
		var partnerId = url.searchParams.get("u");
		if(partnerId) localStorage.setItem('partnerId', partnerId);
	}

	render() {
		const Unauthorized = ({ component: Component, ...rest}) => (
			<Route {...rest} render={props => (
				<ReactSwipe {...props}>
					<Component {...props} {...rest}/>
				</ReactSwipe>
			)}/>
		);

		return (
			<Router basename={Config.basename}>
				<Switch>
					<Unauthorized exact path="/auth/:page?" component={Auth} />
					<Unauthorized exact path="/admin/:section?" component={Admin} />
					
					<Unauthorized exact path="/:number" component={Phone} />
					<Unauthorized exact path="/" component={Search} />
					
					{/*<RedirectToMain exact path="/" />*/}
					<Unauthorized path="*" component={NotFound} />
				</Switch>
			</Router>
		)
	}
}

export default App;