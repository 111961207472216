import React from 'react'
import Config from '../../../config'
import { connect } from 'react-redux'
import ReactSwipeEvents from 'react-swipe-events'
import { YMInitializer } from 'react-yandex-metrika'

import Alerts from '../alerts'
import ModalCrop from '../modalCrop'

//import { getBalance } from '../../../actions/payment'
import { getAppInfo } from '../../../actions/optionsApp'

import './index.scss'

class ReactSwipe extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			swiping: false,
			maxGoBackDeltaX: 220,
		}
		//this.props.getAppInfo();
	}

	componentDidMount() {
		//this.props.getAppInfo()
	}

	componentWillUpdate() {
		window.innerWidth <= 768 ? localStorage.setItem('mobileVersion', true) : localStorage.removeItem('mobileVersion');
	
		let { pathname } = this.props.location;

		let pagePathname = pathname === '/auth/login' || pathname === '/auth/recover' || pathname === '/auth/register';

		if(pagePathname) {
			document.querySelector('body').style.overflow = 'hidden';
			//document.querySelector('body').className = "";
		} else {
			document.querySelector('body').style.overflow = '';
			//document.querySelector('body').className = localStorage.theme;
		}
	}

	render() {
		const {
			swiping,
			maxGoBackDeltaX,
		} = this.state;

		const {
			urlGoBack,
			showGoBack,
			//yandex_metrika_id
		} = this.props.app;

		return ( 
			<ReactSwipeEvents
				id="swipe"
				className={`${swiping ? 'swiping' : ''}`}
				onSwiping={(e, originalX, originalY, currentX, currentY, deltaX, deltaY) => {
					if(deltaX >= 0  && showGoBack) {

						let minDelta = deltaX >= 50 ? deltaX : deltaX < 0 ? deltaX : 0;

						document.querySelector('#swipe').style.left = minDelta+'px';
						document.querySelector('#swipe').style.opacity = 1 - minDelta/300;

						if(deltaX >= 50) {
							this.setState({swiping: true})
						}
					}
				}}
				onSwiped={(e, originalX, originalY, endX, endY, deltaX, deltaY) => {
					this.setState({swiping: false})

					document.querySelector('#swipe').style.left = 0;
					document.querySelector('#swipe').style.opacity = 1;

					if(deltaX >= maxGoBackDeltaX && showGoBack) {
						if(urlGoBack) {
							this.props.history.push(urlGoBack);
							return false;
						}

						this.props.history.goBack();
					}
				}}
				style={{position: 'absolute'}}
			>

				<div style={{position: 'absolute', width: '100%'}}>
					<div className={`app-content ${!this.props.app.bottomNav ? 'bottomNavHidden' : ''}`}>
						{this.props.children}
					</div>

					<ModalCrop />

	 				{Config.prod ?
	 					<YMInitializer 
		 					accounts={['88274876']} 
		 					options={{
			 					clickmap:true,
						        trackLinks:true,
						        accurateTrackBounce:true,
						        webvisor:true,
						        trackHash:true
						    }} 
						    version="2" 
					    />
					: false}

					<Alerts />
				</div>
			</ReactSwipeEvents>
		);
	}
}

const mapStateToProps = (state) => ({
	app: state.app,
})

const mapDispatchToProps = (dispatch) => ({
	//getBalance: (data) => dispatch(getBalance(data)),
	getAppInfo: (data) => dispatch(getAppInfo(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReactSwipe);