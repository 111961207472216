import React from 'react'
import Popup from 'reactjs-popup'
import { connect } from 'react-redux'

import { addQuestion } from '../../../../actions/phone'

class ModalAddQuestion extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			text: "",
			email: ""
		};
	}

	close() {
		this.setState({
			text: "",
			email: ""
		});

		this.props.close();
	}

	addQuestion() {
		let { text, email } = this.state;

		this.props.addQuestion({
			text: text,
			email: email,
		});

		this.close();
	}

	render() {
		let { text, email } = this.state;

		return (
			<Popup 
				modal
				open={true}
				onClose={() => this.close()}
			>
				{close => (
					<div className="modal modal-add-tag">

						<div className="close" onClick={() => close()}>
							×
						</div>

						<div className="content">
							<input
								className="email-input"
								placeholder="Почта для ответа"
								value={email}
								onChange={(e) => this.setState({email: e.target.value})}
							/>
							<textarea
								className="text-textarea"
								placeholder="Внимательно слушаем вас, рассказывайте"
								value={text}
								onChange={(e) => this.setState({text: e.target.value})}
							/>
							<button 
								className="btn btn-transparent"
								onClick={() => this.addQuestion()}
							>
								Отправить сообщение
							</button>
						</div>

					</div>
				)}
			</Popup>
		)
	}

}

const mapStateToProps = (state) => ({
	phone: state.phone
})

const mapDispatchToProps = (dispatch) => ({
	addQuestion: (data) => dispatch(addQuestion(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddQuestion);