import React from 'react'

import './index.scss'

class NotFound extends React.Component {

	render() {
	    return (
			<div className="notFound">
				<div className="title">404</div>
				<div className="desc">Not Found</div>
				<button className="btn btn-blue" onClick={() => this.props.history.push('/')}>
					<span className="fa fa-reply"/>
					<span className="error_btn_text">Вернуться на главную</span>
				</button>
			</div>
	    )
	}
}

export default NotFound;