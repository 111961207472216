import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import InputMask from 'react-input-mask'
import PhoneInput from 'react-phone-input-2'

import { 
	loginPhone,
	backToFormPhone,
	confirmLoginPhone
} from '../../../actions/authLoginPhone'

//import DivLink from '../../elements/divLink'

//import 'react-phone-input-2/lib/style.css'
import '../index.scss'

class AuthPhone extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			phone: '7',
			confirm_code: '',
			formattedPhone: ''
		};

		this.pressEnter = this.pressEnter.bind(this);
	}

	pressEnter(keyPress) {
		if(keyPress.keyCode === 13) {
			let {
				phone
			} = this.state;
	
			let allowLoginEmail = phone;

			if(allowLoginEmail) this.loginPhone();
		}
	}

	componentDidMount() {
		window.addEventListener('keydown', this.pressEnter, false)
	}

	componentWillUnmount() {
		delete(this.props.authLoginPhone.phone_received);
		window.removeEventListener('keydown', this.pressEnter, false);
	}

	loginPhone() {
		const {
			phone,
			formattedPhone
		} = this.state;

		this.props.loginPhone({
			phone: phone,
			formatted_phone: formattedPhone
		});
	}

	confirmLoginPhone() {
		const {
			phone,
			confirm_code
		} = this.state;

		this.props.confirmLoginPhone({
			phone: phone,
			confirm_code: confirm_code
		});
	}

	formPhone() {
		const {
			phone
		} = this.state;

		return (
			<div className="form">
				<div className="inputs">
					<PhoneInput
						country="ru"
						value={phone}
						specialLabel=""
						autoFormat={true}
						placeholder="Телефон"
						onChange={(phone, country, e, formattedPhone) => this.setState({phone, formattedPhone})}
					/>
				</div>

				<div className="buttons buttons-window">
					<div className="row">
						<button 
							className="btn btn-black enter"
							onClick={() => this.loginPhone()}
						>Продолжить</button>
					</div>
				</div>

				<div className="row subtitle policy">
					<span>
						Вы соглашаетесь с <span className="underline">условиями использования</span> и даете<br/>
						<span className="underline">согласие на обработку персональных данных.</span>
					</span>
				</div>

			</div>
		)
	}

	formConfirmCode() {
		const {
			confirm_code
		} = this.state;

		const { authLoginPhone } = this.props;

		return (
			<div className="form">
				<div className="desc">
					Код отправлен на номер <br/>
					{authLoginPhone.phone_received}
				</div>

				<div className="inputs">
					<InputMask 
						mask="9999"
						value={confirm_code}
						placeholder="Код"
						maskPlaceholder=""
						alwaysShowMask={false}
						className="confirmCode"
						onChange={e => this.setState({confirm_code: e.target.value})}
					/>
				</div>

				<div className="buttons buttons-window">
					<div className="row">
						<button 
							className="btn btn-black enter"
							onClick={() => this.confirmLoginPhone()}
						>Войти</button>
					</div>
				</div>

				<div className="row subtitle">
					<button className="btn-back" onClick={() => this.props.backToFormPhone()}>Изменить номер телефона</button>
				</div>

			</div>
		)
	}

	render() {
		if(localStorage.token) return <Redirect to='/' />;

		const { panel, authLoginPhone } = this.props;

		return (
			<div className={`auth auth-login-phone ${panel ? 'panel' : 'window'}`}>
				{!authLoginPhone.phone_received ? this.formPhone() : this.formConfirmCode()}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	authLoginPhone: state.authLoginPhone
})

const mapDispatchToProps = (dispatch) => ({
	loginPhone: (data) => dispatch(loginPhone(data)),
	backToFormPhone: (data) => dispatch(backToFormPhone(data)),
	confirmLoginPhone: (data) => dispatch(confirmLoginPhone(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthPhone)