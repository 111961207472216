import React from 'react'
import { connect } from 'react-redux'

import './index.scss'

class NotFoundPhone extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			
		};
	}

	componentDidMount() {
		let addPhone = document.querySelector('.add-phone');
		let notFoundPhone = document.querySelector('.notFoundPhone');

		if(notFoundPhone) notFoundPhone.appendChild(addPhone);
	}

	componentWillUnmount() {
		let addPhone = document.querySelector('.add-phone');
		let notFoundPhone = document.querySelector('.header');

		if(notFoundPhone) notFoundPhone.appendChild(addPhone);
	}

	render() {

		return (
			<div className="content notFoundPhone">

				<h1>Упс… такого номера у нас<br/> еще нет</h1>

			</div>
		)
	}

}

const mapStateToProps = (state) => ({
	phone: state.phone
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundPhone);