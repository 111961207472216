import React from 'react'
import Popup from 'reactjs-popup'
import { connect } from 'react-redux'

import { addTag } from '../../../../actions/phone'

class ModalAddTag extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			tagName: ""
		};
	}

	close() {
		this.setState({tagName: ""});

		this.props.close();
	}

	addTag() {
		let { tagName } = this.state;
		let { number } = this.props.phone;

		this.close();
		this.props.addTag({
			name: tagName,
			number: number,
		});
	}

	render() {
		let { tagName } = this.state;

		return (
			<Popup 
				modal
				open={true}
				onClose={() => this.close()}
			>
				{close => (
					<div className="modal modal-add-tag">

						<div className="close" onClick={() => close()}>
							×
						</div>

						<div className="content">
							<input
								className="tag-input"
								placeholder="Например, «Звонят из банка»"
								value={tagName}
								onChange={(e) => this.setState({tagName: e.target.value})}
							/>
							<button 
								className="btn btn-transparent"
								onClick={() => this.addTag()}
							>
								Добавить тег
							</button>
						</div>

					</div>
				)}
			</Popup>
		)
	}

}

const mapStateToProps = (state) => ({
	phone: state.phone
})

const mapDispatchToProps = (dispatch) => ({
	addTag: (data) => dispatch(addTag(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddTag);