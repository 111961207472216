import { 
	BACK_TO_FORM_PHONE,
	LOGIN_PHONE_REQUEST,
	LOGIN_PHONE_FAILURE,
	LOGIN_PHONE_SUCCESS,
	CONFIRM_LOGIN_PHONE_REQUEST,
	CONFIRM_LOGIN_PHONE_FAILURE,
	CONFIRM_LOGIN_PHONE_SUCCESS,
} from '../actions/types';

const initialState = {

}

export default function(state = initialState, action ) {
	switch(action.type) {
		case LOGIN_PHONE_REQUEST:
			return {
				...state,
				loading: true,
			}
		case LOGIN_PHONE_FAILURE:
			return {
				...state,
				...action.payload,
				loading: false,
			}
		case LOGIN_PHONE_SUCCESS:
			return {
				...state,
				...action.payload,
				loading: false,
			}

		case CONFIRM_LOGIN_PHONE_REQUEST:
			return {
				...state,
				loading: true,
			}
		case CONFIRM_LOGIN_PHONE_FAILURE:
			return {
				...state,
				...action.payload,
				loading: false,
			}
		case CONFIRM_LOGIN_PHONE_SUCCESS:
			return {
				...state,
				...action.payload,
				loading: false,
			}

		case BACK_TO_FORM_PHONE:
			return {};

		default: 
			return state;
	}
}