import React from 'react'
import { connect } from 'react-redux'

import ModalAddPhone from '../modals/addPhone'

import './index.scss'

class Header extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			modalAddPhoneShow: false
		};
	}

	render() {
		let {
			modalAddPhoneShow,
		} = this.state;

		return (
			<div className="header">
				<button 
					className="btn btn-transparent add-phone"
					onClick={() => this.setState({modalAddPhoneShow: true})}
				>
					+ Добавить номер
				</button>

				{modalAddPhoneShow ? <ModalAddPhone close={() => this.setState({modalAddPhoneShow: false})} /> : false}
			</div>
		)
	}

}

const mapStateToProps = (state) => ({
	
})

const mapDispatchToProps = (dispatch) => ({
	
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);