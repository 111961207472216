import {
	REGISTER_EMAIL_REQUEST,
	REGISTER_EMAIL_FAILURE,
	REGISTER_EMAIL_SUCCESS,
} from './types'
import { 
	fetchToAPI,
	//createAuthLocalStorage,
} from './optionsApp'

import { userList } from './admin'

export const registerEmail = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/admin/registerEmail',
		data: data,
		method: 'POST',
		request: res => {
			return { 
				type: REGISTER_EMAIL_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: REGISTER_EMAIL_FAILURE,
				payload: res
			}
		},
		success: res => {
			dispatch(userList())
			//dispatch(createAuthLocalStorage(res))
			return { 
				type: REGISTER_EMAIL_SUCCESS,
				payload: res
			}
		}
	}))
}