import React from 'react'
//import Config from '../../../config'
import { history } from '../../../store'
import Preload from '../../elements/preload'
import Pagination from 'react-js-pagination'

import 'react-day-picker/lib/style.css'

class CabinetTable extends React.Component {

	constructor(props) {
		super(props);

	    let activePage = props.location ? new URLSearchParams(props.location.search).get('page') : null;

	    // let {
	    // 	search,
	    // 	pathname
	    // } = props.location;

	    if(!activePage) activePage = 1;
	    //if(activePage > 1) history.replace(Config.basename + pathname + search + '?page='+activePage);

	    this.state = {
	    	dateTo: null,
	    	dateFrom: null,
	    	itemsCountPerPage: props.itemsCountPerPage ? props.itemsCountPerPage : 10,
	    	activePage: +activePage,
	    };

	}

	removeURLParameter(url, parameter) {
		var urlparts= url.split('?');   
		if (urlparts.length>=2) {

			var prefix= encodeURIComponent(parameter)+'=';
			var pars= urlparts[1].split(/[&;]/g);

			//reverse iteration as may be destructive
			for (var i= pars.length; i-- > 0;) {    
				//idiom for string.startsWith
				if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
					pars.splice(i, 1);
				}
			}
			
			if(pars.length > 0) {
				url= urlparts[0]+'&'+pars.join('&');
			} else {
				url= urlparts[0];
			}

			return url;
		} else {
			return url;
		}
	}

	handlePageChange(newActivePage, requestToServer = true) {
		let {
	    	pathname
	    } = this.props.location;

	    let filterData = {};
	    let search = window.location.search;


	    var params = new URLSearchParams(search);
	    for (let p of params) {
	    	if(p[0] !== "page") filterData[p[0]] = p[1];
	    }


	    let activePage = new URLSearchParams(search).get('page');

		if(!activePage || newActivePage !== activePage) {
			let newSearch = "?page=" + newActivePage + this.removeURLParameter(search, "page");

			history.replace(pathname + newSearch);
		}

		this.setState({activePage: newActivePage});

		if(requestToServer) {
			this.requestToServer({
				isLoaded: false,
				activePage: newActivePage,
				...filterData
			});
		}

		if(this.props.scrollTop) document.querySelector('html').scrollTop = 0;
	}

	requestToServer(params = {}) {
		let { activePage } = this.state;

		this.props.requestToServer({
			isLoaded: params.isLoaded,
			active_page: +(params.activePage ? params.activePage : activePage)
		});
	}

	componentDidMount() {
		this.requestToServer();

		let { requestToServerInterval } = this.props;

		if(requestToServerInterval && +requestToServerInterval > 0) {

			this.updateTable = setInterval(() => {
				this.requestToServer();
			}, requestToServerInterval * 1000);
		}

	}

	componentDidUpdate(prevProps, prevState) {
		if(this.props.activePage && this.props.activePage !== this.state.activePage) this.handlePageChange(this.props.activePage, false);

		let categoryChanged = this.props.match ? this.props.match.params.category !== prevProps.match.params.category : false;

		if(categoryChanged) {
			this.componentWillUnmount();
			this.componentDidMount();
		}
	}

	componentWillUnmount() {
		let { willUnmount } = this.props;

		clearInterval(this.updateTable);
		if(willUnmount) willUnmount();
	}

	render() {
		if(!this.props.isLoaded) return <Preload/>;

		const { 
			activePage,
			itemsCountPerPage
		} = this.state;

    	const {
    		thead,
    		tbody,
    		title,
    		//children,
    		countItems,
    		paginationNextBack
    	} = this.props;

		return (
			<div className="cabinet-table">
				{title ? <div className="head">{title}</div> : false}
				<div className="body">
					
					<table className="table table-adaptive">
						<thead>
							{thead}
						</thead>
						<tbody>
							{tbody ? tbody : this.emptyTableBody()}
						</tbody>
					</table>

					{/*{children}*/}
				</div>

				{countItems > itemsCountPerPage ? 
					paginationNextBack ?
						<div className="pages">
							<div className="count">
								{`${activePage}/${Math.ceil(countItems/itemsCountPerPage)}`}
							</div>
							<Pagination
								hideFirstLastPages
								prevPageText="Предыдущая"
								nextPageText="Следущая"
								activePage={activePage}
								itemsCountPerPage={itemsCountPerPage}
								totalItemsCount={+countItems}
								pageRangeDisplayed={1}
								activeClass="hidden"
								itemClassNext="link-next"
								onChange={this.handlePageChange.bind(this)}
							/>
						</div>
					:
						<Pagination
							prevPageText="<"
							nextPageText=">"
							activePage={activePage}
							itemsCountPerPage={itemsCountPerPage}
							totalItemsCount={+countItems}
							pageRangeDisplayed={5}
							onChange={this.handlePageChange.bind(this)}
						/>
				: false}
			</div>
		)
	}

	emptyTableBody() {
		let { emptyTableBodyText } = this.props;

		emptyTableBodyText = emptyTableBodyText ? emptyTableBodyText : "Данные отсутствуют";

		return (
			<tr className="empty-content">
				<td colSpan="100%">{emptyTableBodyText}</td>
			</tr>
		)
	}
}

export default CabinetTable;