import {
	ADD_PHONE_TAG_REQUEST,
	ADD_PHONE_TAG_FAILURE,
	ADD_PHONE_TAG_SUCCESS,
	GET_PHONE_INFO_REQUEST,
	GET_PHONE_INFO_FAILURE,
	GET_PHONE_INFO_SUCCESS,
	ADD_PHONE_NUMBER_REQUEST,
	ADD_PHONE_NUMBER_FAILURE,
	ADD_PHONE_NUMBER_SUCCESS,
	ADD_PHONE_QUESTION_REQUEST,
	ADD_PHONE_QUESTION_FAILURE,
	ADD_PHONE_QUESTION_SUCCESS,
	ADD_PHONE_COMPLAINT_REQUEST,
	ADD_PHONE_COMPLAINT_FAILURE,
	ADD_PHONE_COMPLAINT_SUCCESS,
	GET_ADDITIONALS_TAGS_REQUEST,
	GET_ADDITIONALS_TAGS_FAILURE,
	GET_ADDITIONALS_TAGS_SUCCESS,
} from './types'
import { fetchToAPI } from './optionsApp'

export const getInfo = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/phone/getInfo',
		data: data,
		request: res => {
			return { 
				type: GET_PHONE_INFO_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: GET_PHONE_INFO_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: GET_PHONE_INFO_SUCCESS,
				payload: res
			}
		},
		serverError: res => {
			
		}
	}))
}

export const getAdditionalTags = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/phone/getAdditionalTags',
		data: data,
		request: res => {
			return { 
				type: GET_ADDITIONALS_TAGS_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: GET_ADDITIONALS_TAGS_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: GET_ADDITIONALS_TAGS_SUCCESS,
				payload: res
			}
		},
		serverError: res => {
			
		}
	}))
}

export const addNumber = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/phone/addNumber',
		data: data,
		request: res => {
			return { 
				type: ADD_PHONE_NUMBER_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: ADD_PHONE_NUMBER_FAILURE,
				payload: res
			}
		},
		success: res => {

			return { 
				type: ADD_PHONE_NUMBER_SUCCESS,
				payload: res
			}
		},
		serverError: res => {
			
		}
	}))
}

export const addTag = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/phone/addTag',
		data: data,
		request: res => {
			return { 
				type: ADD_PHONE_TAG_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: ADD_PHONE_TAG_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: ADD_PHONE_TAG_SUCCESS,
				payload: res
			}
		},
		serverError: res => {
			
		}
	}))
}

export const addComplaint = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/phone/addComplaint',
		data: data,
		request: res => {
			return { 
				type: ADD_PHONE_COMPLAINT_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: ADD_PHONE_COMPLAINT_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: ADD_PHONE_COMPLAINT_SUCCESS,
				payload: res
			}
		},
		serverError: res => {
			
		}
	}))
}

export const addQuestion = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/phone/addQuestion',
		data: data,
		request: res => {
			return { 
				type: ADD_PHONE_QUESTION_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: ADD_PHONE_QUESTION_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: ADD_PHONE_QUESTION_SUCCESS,
				payload: res
			}
		},
		serverError: res => {
			
		}
	}))
}