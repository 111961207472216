import {
	BACK_TO_FORM_PHONE,
	LOGIN_PHONE_REQUEST,
	LOGIN_PHONE_FAILURE,
	LOGIN_PHONE_SUCCESS,
	CONFIRM_LOGIN_PHONE_REQUEST,
	CONFIRM_LOGIN_PHONE_FAILURE,
	CONFIRM_LOGIN_PHONE_SUCCESS,
} from './types'
import { 
	fetchToAPI,
	createAuthLocalStorage,
} from './optionsApp'

export const loginPhone = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/auth/loginPhone',
		data: data,
		method: 'POST',
		request: res => {
			return { 
				type: LOGIN_PHONE_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: LOGIN_PHONE_FAILURE,
				payload: res
			}
		},
		success: res => {
			dispatch(createAuthLocalStorage(res))
			return { 
				type: LOGIN_PHONE_SUCCESS,
				payload: res
			}
		}
	}))
}

export const confirmLoginPhone = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/auth/confirmLoginPhone',
		data: data,
		method: 'POST',
		request: res => {
			return { 
				type: CONFIRM_LOGIN_PHONE_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: CONFIRM_LOGIN_PHONE_FAILURE,
				payload: res
			}
		},
		success: res => {
			dispatch(createAuthLocalStorage(res))
			return { 
				type: CONFIRM_LOGIN_PHONE_SUCCESS,
				payload: res
			}
		}
	}))
}

export const backToFormPhone = data => dispatch => {
	dispatch({ 
		type: BACK_TO_FORM_PHONE,
		payload: data
	})
}
