import { combineReducers } from 'redux'

import app from './app'
import admin from './admin'
import phone from './phone'
import authLogin from './authLogin'
import authLogout from './authLogout'
import authRecover from './authRecover'
import authRegister from './authRegister'
import authLoginPhone from './authLoginPhone'

export default combineReducers({
	app: app,
	admin: admin,
	phone: phone,
	authLogin: authLogin,
	authLogout: authLogout,
	authRecover: authRecover,
	authRegister: authRegister,
	authLoginPhone: authLoginPhone,
});