import { 
	GET_ADMIN_INFO_REQUEST,
	GET_ADMIN_INFO_FAILURE,
	GET_ADMIN_INFO_SUCCESS,
	GET_PHONE_LIST_REQUEST,
	GET_PHONE_LIST_FAILURE,
	GET_PHONE_LIST_SUCCESS,
	DELETE_ADMIN_TAG_REQUEST,
	DELETE_ADMIN_TAG_FAILURE,
	DELETE_ADMIN_TAG_SUCCESS,
	IMPORT_ADMIN_FILE_REQUEST,
	IMPORT_ADMIN_FILE_FAILURE,
	IMPORT_ADMIN_FILE_SUCCESS,
	DELETE_ADMIN_PHONE_REQUEST,
	DELETE_ADMIN_PHONE_FAILURE,
	DELETE_ADMIN_PHONE_SUCCESS,
	GET_ADMIN_USER_LIST_REQUEST,
	GET_ADMIN_USER_LIST_FAILURE,
	GET_ADMIN_USER_LIST_SUCCESS,
	GET_ADMIN_ADDITIONALS_TAGS_REQUEST,
	GET_ADMIN_ADDITIONALS_TAGS_FAILURE,
	GET_ADMIN_ADDITIONALS_TAGS_SUCCESS,

	GET_COMPLAINT_LIST_REQUEST,
	GET_COMPLAINT_LIST_FAILURE,
	GET_COMPLAINT_LIST_SUCCESS,
	GET_QUESTION_LIST_REQUEST,
	GET_QUESTION_LIST_FAILURE,
	GET_QUESTION_LIST_SUCCESS,
	DELETE_QUESTION_REQUEST,
	DELETE_QUESTION_FAILURE,
	DELETE_QUESTION_SUCCESS,
	DELETE_COMPLAINT_REQUEST,
	DELETE_COMPLAINT_FAILURE,
	DELETE_COMPLAINT_SUCCESS,
} from '../actions/types';

const initialState = {
	users: {
		list: []
	},
	phones: {
		list: []
	},
	complaints: {
		list: []
	},
	questions: {
		list: []
	}
}

export default function(state = initialState, action ) {
	switch(action.type) {
		case GET_ADMIN_INFO_REQUEST:
			return {
				...state,
			}
		case GET_ADMIN_INFO_FAILURE:
			return {
				...state,
				...action.payload,
			}
		case GET_ADMIN_INFO_SUCCESS:
			return {
				...state,
				...action.payload,
			}

		case GET_PHONE_LIST_REQUEST:
			return {
				...state,
			}
		case GET_PHONE_LIST_FAILURE:
			return {
				...state,
				...action.payload,
			}
		case GET_PHONE_LIST_SUCCESS:
			return {
				...state,
				phones: {
					isLoaded: true,
					...action.payload
				}
			}

		case GET_ADMIN_ADDITIONALS_TAGS_REQUEST:
			return {
				...state,
			}
		case GET_ADMIN_ADDITIONALS_TAGS_FAILURE:
			return {
				...state,
				...action.payload,
			}
		case GET_ADMIN_ADDITIONALS_TAGS_SUCCESS:

			state.phones.list.map((item) => {
				if(item.id === action.payload.phone_id) {
					item.tags = [...item.tags, ...action.payload.tags];
				}
				return item;
			});

			return {
				...state
			}

		case GET_ADMIN_USER_LIST_REQUEST:
			return {
				...state,
			}
		case GET_ADMIN_USER_LIST_FAILURE:
			return {
				...state,
				...action.payload,
			}
		case GET_ADMIN_USER_LIST_SUCCESS:
			return {
				...state,
				users: {
					isLoaded: true,
					...action.payload
				}
			}

		case DELETE_ADMIN_TAG_REQUEST:
			return {
				...state,
			}
		case DELETE_ADMIN_TAG_FAILURE:
			return {
				...state,
				...action.payload,
			}
		case DELETE_ADMIN_TAG_SUCCESS:
			let newTagList = state.phones.list.map((item) => {
				if(item.id === action.payload.phone_id) {
					item.tags = item.tags.filter((item) => 
						item.id !== action.payload.tag_id ? item : false
					);
				}
				return item;
			});

			return {
				...state,
				phones: {
					isLoaded: true,
					list: newTagList
				}
			}

		case DELETE_ADMIN_PHONE_REQUEST:
			return {
				...state,
			}
		case DELETE_ADMIN_PHONE_FAILURE:
			return {
				...state,
				...action.payload,
			}
		case DELETE_ADMIN_PHONE_SUCCESS:
			let newPhoneList = state.phones.list.filter((item) =>
				item.id !== action.payload.phone_id ? item : false
			);

			return {
				...state,
				phones: {
					isLoaded: true,
					list: newPhoneList,
					count: +state.phones.count - 1,
				},
			}

		case GET_COMPLAINT_LIST_REQUEST:
			return {
				...state,
			}
		case GET_COMPLAINT_LIST_FAILURE:
			return {
				...state,
				...action.payload,
			}
		case GET_COMPLAINT_LIST_SUCCESS:
			return {
				...state,
				complaints: {
					isLoaded: true,
					...action.payload
				}
			}

		case GET_QUESTION_LIST_REQUEST:
			return {
				...state,
			}
		case GET_QUESTION_LIST_FAILURE:
			return {
				...state,
				...action.payload,
			}
		case GET_QUESTION_LIST_SUCCESS:
			return {
				...state,
				questions: {
					isLoaded: true,
					...action.payload
				}
			}

		case DELETE_QUESTION_REQUEST:
			return {
				...state,
			}
		case DELETE_QUESTION_FAILURE:
			return {
				...state,
				...action.payload,
			}
		case DELETE_QUESTION_SUCCESS:
			let newQuestionList = state.questions.list.filter((item) =>
				item.id !== action.payload.question_id ? item : false
			);

			return {
				...state,
				questions: {
					isLoaded: true,
					list: newQuestionList,
					count: +state.questions.count - 1,
				}
			}

		case DELETE_COMPLAINT_REQUEST:
			return {
				...state,
			}
		case DELETE_COMPLAINT_FAILURE:
			return {
				...state,
				...action.payload,
			}
		case DELETE_COMPLAINT_SUCCESS:
			let newComplaintsList = state.complaints.list.filter((item) => 
				item.id !== action.payload.complaint_id ? item : false
			);

			return {
				...state,
				complaints: {
					isLoaded: true,
					list: newComplaintsList,
					count: +state.complaints.count - 1,
				}
			}

		case IMPORT_ADMIN_FILE_REQUEST:
			return {
				...state,
				importManagement: null
			}
		case IMPORT_ADMIN_FILE_FAILURE:
			return {
				...state,
				...action.payload,
			}
		case IMPORT_ADMIN_FILE_SUCCESS:
			return {
				...state,
				importManagement: action.payload,
			}

		default: 
			return state;
	}
}